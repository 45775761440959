import React, { useContext } from 'react';
import moment from 'moment';
import {
  Grid,
  Button,
  Stack
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { InputField, InputPasswordField, SelectField, SelectFieldAvatar } from '../../../components/form-fields';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';
import AuthContext from '../../../context/authContext';

import validationSchema from './form-model/validationSchema';
import userFormModel from './form-model/userFormModel';
import formInitialValues from './form-model/formInitialValues';
import avatars from './avatars';

export default function UserForm({data, kantorDropDown, handleCloseModal, handleUpdateTable}) {
  const { user } = useContext(AuthContext);
  const notificationCtx = useContext(NotificationContext);
  const { formId, formField } = userFormModel;
  const { firstName, lastName, email, avatar, role, kantor, password, repassword, status } = formField;

  const _handleInitialValues = data !== undefined ?  {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      avatar: data.avatar,
      role: data.role,
      kantor: data.kantor,
      password: '',
      repassword: '',
      status: data.status ? 1 : 3,
    } : formInitialValues
    

  const roles = [
    {
      value: 'admin',
      label: 'Administrator'
    },
    {
      value: 'pusat',
      label: 'Pusat'
    },
    {
      value: 'absen',
      label: 'Absen'
    },
    // {
    //   value: 'cabang',
    //   label: 'Cabang'
    // }
  ]

  const statusDropdown = [
    {
      value: 1,
      label: 'Aktif'
    },
    {
      value: 3,
      label: 'Tidak Aktif'
    },
  ]
  
  const handleMessage = async (params) => {
    const success = params?.success;

    if (success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: params?.message
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.message
      });
    }
  };

  const _handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const {firstName, lastName, email, avatar, role, kantor, password, repassword, status} = values;

    if(!data?._id ) {
      if(password.length < 6) {
        notificationCtx.setup({
          severity: 'error',
          title: 'Error',
          message: 'Minimal password 6 karakter!'
        });
  
        return;
      }
    }

    if(password !== repassword) {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: 'Password tidak sama!'
      });

      return;
    }

    const params = data?._id ? {
      firstName,
      lastName,
      email,
      avatar,
      role,
      kantor,
      password: password === '' ? undefined : password,
      status: status === 1,
      dibuatOleh: user?._id,
      tglUbah: moment().format('YYYY-MM-DDTHH:mm:ss')
    } : {
      firstName,
      lastName,
      email,
      avatar,
      role,
      kantor,
      password,
      status: status === 1,
      dibuatOleh: user?._id,
      tglBuat: moment().format('YYYY-MM-DDTHH:mm:ss')
    }

    // console.log(params);

    const endpoint = data?._id
      ? axiosPrivate.put(`/user/${data._id}`, params)
      : axiosPrivate.post(`/user`, params);

    try {
      const response = await endpoint;
      const { result } = response?.data;

      handleUpdateTable(result);
      handleMessage(response?.data);

      if (!data?._id) {
        resetForm();
      }
    } catch (error) {
      handleMessage(error);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Formik
        initialValues={_handleInitialValues}
        validationSchema={validationSchema}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form id={formId}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <InputField name={firstName.name} label={firstName.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputField name={lastName.name} label={lastName.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputField name={email.name} label={email.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectFieldAvatar
                  name={avatar.name}
                  label={avatar.label}
                  data={avatars}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectField
                  name={role.name}
                  label={role.label}
                  data={roles}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectField
                  name={kantor.name}
                  label={kantor.label}
                  data={kantorDropDown}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputPasswordField name={password.name} label={password.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputPasswordField name={repassword.name} label={repassword.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectField
                  name={status.name}
                  label={status.label}
                  data={statusDropdown}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseModal}
              >
                Kembali
              </Button>
              <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                Simpan
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
}
