import * as Yup from 'yup';
import saldoCutiFormModel from './saldoCutiFormModel';

const {
  formField: {
    kodePegawai,
    tahun,
    saldo
  }
} = saldoCutiFormModel;

export default Yup.object({
  [kodePegawai.name]: Yup.string().required(`${kodePegawai.requiredErrorMsg}`),
  [tahun.name]: Yup.string().required(`${tahun.requiredErrorMsg}`),
  [saldo.name]: Yup.string().required(`${saldo.requiredErrorMsg}`)
});
