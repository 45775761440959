import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { InputField, CheckboxField, SelectField, DatePickerField } from '../../../../components/form-fields';

const jenisKelamins = [
  {
    value: 'Laki-Laki',
    label: 'Laki-Laki'
  },
  {
    value: 'Perempuan',
    label: 'Perempuan'
  }
];

const statusPerkawinans = [
  {
    value: 'Menikah',
    label: 'Menikah'
  },
  {
    value: 'Belum Menikah',
    label: 'Belum Menikah'
  }
];

const pendidikans = [
  {
    value: 'SD',
    label: 'SD'
  },
  {
    value: 'SMP',
    label: 'SMP'
  },
  {
    value: 'SMA',
    label: 'SMA'
  },
  {
    value: 'D1',
    label: 'D1'
  },
  {
    value: 'D2',
    label: 'D2'
  },
  {
    value: 'D3',
    label: 'D3'
  },
  {
    value: 'S1',
    label: 'S1'
  },
  {
    value: 'S2',
    label: 'S2'
  },
  {
    value: 'TS',
    label: 'Tidak Sekolah'
  },
];

const agamas = [
  {
    value: 'Islam',
    label: 'Islam'
  },
  {
    value: 'Protestan',
    label: 'Protestan'
  },
  {
    value: 'Katolik',
    label: 'Katolik'
  },
  {
    value: 'Hindu',
    label: 'Hindu'
  }
];

export default function ProfilForm(props) {
  const {
    dropDown,
    formField: {
      kode,
      idAnggota,
      nama,
      namaTanpaGelar,
      jenisKelamin,
      tempatLahir,
      tglLahir,
      statusPerkawinan,
      agama,
      pendidikan,
      ukuranBaju,
      kotaKab,
      kecamatan,
      alamat,
      kotaKabDomisili,
      kecamatanDomisili,
      alamatDomisili,
      useDomisili
    }
  } = props;
  const kotaKabs = (dropDown || []).filter(x => x.kategori === 'kotakab');
  const kecamatans = (dropDown || []).filter(x => x.kategori === 'kecamatan');
  const [domisili, setDomisili] = useState(props.useDomisili);

  const handleChange = (e) => {
    setDomisili(e.target.value !== 'true');
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <InputField name={kode.name} label={kode.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={idAnggota.name} label={idAnggota.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={nama.name} label={nama.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={namaTanpaGelar.name} label={namaTanpaGelar.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={jenisKelamin.name}
            label={jenisKelamin.label}
            data={jenisKelamins}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={tempatLahir.name} label={tempatLahir.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            name={tglLahir.name}
            label={tglLahir.label}
            format="dd/MM/yyyy"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={statusPerkawinan.name}
            label={statusPerkawinan.label}
            data={statusPerkawinans}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={pendidikan.name}
            label={pendidikan.label}
            data={pendidikans}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={agama.name}
            label={agama.label}
            data={agamas}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={ukuranBaju.name} label={ukuranBaju.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={kotaKab.name}
            label={kotaKab.label}
            data={kotaKabs}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={kecamatan.name}
            label={kecamatan.label}
            data={kecamatans}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputField
            name={alamat.name}
            label={alamat.label}
            multiline
            rows={3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            name={useDomisili.name}
            label={useDomisili.label}
            onChange={handleChange}
          />
        </Grid>
        {domisili && 
          <>
            <Grid item xs={12} sm={6}>
              <SelectField
                name={kotaKabDomisili.name}
                label={kotaKabDomisili.label}
                data={kotaKabs}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                name={kecamatanDomisili.name}
                label={kecamatanDomisili.label}
                data={kecamatans}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name={alamatDomisili.name}
                label={alamatDomisili.label}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
          </>
        }
      </Grid>
    </>
  );
}
