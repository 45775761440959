import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo, useContext } from 'react';
// @mui
import {
  Box,
  Card,
  Container,
  Button,
  Modal,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import Label from '../../../components/label';
import ImageComponent from '../../../components/files/image';
import { getCurrentDate } from "../../../utils/formatDate";
import Iconify from '../../../components/iconify';
import NotificationContext from '../../../context/notificationContext';
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function CutiRequestPage() {
  const notificationCtx = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [approveLoading, setApproveLoading] = useState(false);
  const [selectedApprove, setSelectedApprove] = useState();
  const [modalImageOpen, setModalImageOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageObjectKey, setImageObjectKey] = useState(null);
  const [notes, setNotes] = useState('');
  const [error, setError] = useState(false);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
     name: "namaPegawai",
     label: "Nama",
     options: {
      filter: true,
      sort: true
     }
    },
    {
      name: "kodePegawai",
      label: "Kode",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "kantor",
      label: "Kantor",
      options: {
       filter: true,
       sort: true
      }
     },
    {
      name: "dariTanggal",
      label: "Dari Tanggal",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "khusus",
      label: "Cuti Khusus",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value ? capitalizeFirstLetter(value) : 'Tidak'
      }
    },
    {
      name: "saldoCuti",
      label: "Saldo Cuti",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "hari",
      label: "Pengajuan",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "approved",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data) => {
          let label;
          let color;
    
          switch (data) {
            case '0':
              label = 'Pending Approval';
              color = 'warning';
              break;
            case '1':
              label = 'Approved';
              color = 'success';
              break;
            case '2':
              label = 'Rejected';
              color = 'error';
              break;
            default:
              label = 'Unknown';
              color = 'default';
          }
    
          return <Label color={color}>{label}</Label>;
        }
      }
    },
    {
      name: "keterangan",
      label: "Keterangan",
      options: {
       filter: false,
       sort: false
      }
     },
    {
      name: "attachment",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customHeadRender: () => null,
        customBodyRender: (value) => (
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleOpenModalImage(value)}
            disabled={value === undefined || value === null || value === ""}
          >
            <Iconify icon={'ant-design:paper-clip-outlined'} sx={{ marginRight: '4px' }} />
            Attachment
          </Button>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => approveLoading ? (
      <CircularProgress
        sx={{
          color: '#637381',
          mr: 2
        }}
        size={20}
      />
    ) : (
      <CheckCircleIcon
        size={20}
        sx={{
          color: '#637381',
          cursor: 'pointer',
          mr: 2
        }}
        onClick={() => handleOpenDialog(selectedRows, displayData, setSelectedRows)}
      />
    ),
    downloadOptions: {
      filename: `req-cuti-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const handleOpenModalImage = (imageUrl) => {
    setImageObjectKey(imageUrl);
    setModalImageOpen(true);
  }

  const handleCloseModalImage = () => {
    setModalImageOpen(false);
  }

  const handleOpenDialog = (selectedRows, displayData, setSelectedRows) => {
    setSelectedApprove({ selectedRows, displayData, setSelectedRows });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setNotes('');
    setError(false);
    setOpenDialog(false);
  };

  const handleApprove = () => {
    setApproveLoading(true);

    const { selectedRows, displayData, setSelectedRows } = selectedApprove;
    const ids = selectedRows.data.map(d => displayData[d.index].data[0]);

    axiosPrivate.post('/req-cuti/approve', {'data' : ids})
    .then((response) => {
      const count = response?.data?.modifiedCount;

      if(count > 0) {
        ids.map((item) => setData(prev => prev.filter(e => e._id !== item)));
        setSelectedRows([]);
      }

      if(count > 0) {
        setData(prev => prev.filter(e => e._id !== ids))
      }

      handleMessage(response, 'Disetujui');
    })
    .catch((error) => handleMessage(error?.response))
    .finally(() => {
      setApproveLoading(false);
      handleCloseDialog();
    });
  }

  const validateNotes = (value) => {
    if (!value || value.length < 1) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  const handleNotesChange = (event) => {
    const { value } = event.target;
    setNotes(value);
    validateNotes(value);
  };

  const handleReject = () => {
    if(!validateNotes(notes)) {
      return false;
    }

    setApproveLoading(true);

    const { selectedRows, displayData, setSelectedRows } = selectedApprove;
    const ids = selectedRows.data.map(d => displayData[d.index].data[0]);

    axiosPrivate.post('/req-cuti/reject', {data : ids, notes})
    .then((response) => {
      const count = response?.data?.modifiedCount;

      if(count > 0) {
        ids.map((item) => setData(prev => prev.filter(e => e._id !== item)));
        setSelectedRows([]);
      }

      if(count > 0) {
        setData(prev => prev.filter(e => e._id !== ids))
      }

      handleMessage(response, 'Direject');
    })
    .catch((error) => handleMessage(error?.response))
    .finally(() => {
      setApproveLoading(false);
      handleCloseDialog();
    });

    return undefined;
  }

  const handleMessage = async (params, action) => {
    const count = params?.data?.modifiedCount;

    if(count > 0) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: `Data Berhasil ${action}.`
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: `Data Gagal ${action}!`
      });
    }
  };

  // Fetch Api
  useEffect(() => {
    axiosPrivate.get('req-cuti/list/request')
    .then((response) => setData(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, []);

  return (
    <>
      <Helmet>
        <title> List Cuti Request | Sip App </title>
      </Helmet>

      <Container>
        <Card>
          <MUIDataTable
            title="List Cuti Request"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        open={modalImageOpen}
        onClose={handleCloseModalImage}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }}>
          <ImageComponent
            objectKey={imageObjectKey}
            onClose={handleCloseModalImage}
          />
        </Box>
      </Modal>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Perhatian!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin menyetujui permohonan cuti ini?
          </DialogContentText>
          <TextField
            margin="dense"
            name="notes"
            value={notes}
            onChange={handleNotesChange}
            label="Notes"
            type="text"
            fullWidth
            variant="standard"
            error={error}
            helperText={error ? "Berikan alasan penolakan." : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReject} color='error'>
            Reject
          </Button>
          <Button onClick={handleApprove} autoFocus>
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
