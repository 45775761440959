import * as Yup from 'yup';
import jabatanFormModel from './jabatanFormModel';

const {
  formField: {
    kode,
    nama,
    grup
  }
} = jabatanFormModel;

export default Yup.object({
  [kode.name]: Yup.string().required(`${kode.requiredErrorMsg}`),
  [nama.name]: Yup.string().required(`${nama.requiredErrorMsg}`),
  [grup.name]: Yup.string().required(`${grup.requiredErrorMsg}`)
});
