import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { id } from "date-fns/locale";
import { parseISO, isValid, format } from 'date-fns';

export default function TimePickerField(props) {
  const { fullWidth } = props;
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const isError = touched && error && true;
  const { value } = field;
  const [formattedValue, setFormattedValue] = useState(null);

  useEffect(() => {
    if (value && !formattedValue) {
      const date = new Date(value);
      const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const formattedDate = format(localDate, "yyyy-MM-dd'T'HH:mm");

      setFormattedValue(formattedDate);
    }
  }, [value, formattedValue]);

  const handleChange = (date) => {
    if (isValid(date)) {
      const timeZoneOffset = date.getTimezoneOffset();
      const localDate = new Date(date.getTime() - timeZoneOffset * 60000);
      const isoString = localDate.toISOString();
      helper.setValue(isoString);
      setFormattedValue(format(date, "yyyy-MM-dd'T'HH:mm"));
    } else {
      console.error('Invalid date input:', date);
      helper.setValue(null);
      setFormattedValue(null);
    }
  };

  return (
    <LocalizationProvider locale={id} dateAdapter={AdapterDateFns}>
      <TimePicker 
        {...field}
        {...props}
        value={formattedValue ? parseISO(formattedValue) : null}
        ampm={false}
        onChange={handleChange}
        fullWidth={fullWidth}
        inputFormat="HH:mm"
        slotProps={{
          textField: {
            fullWidth,
            variant: 'outlined',
            error: isError,
            helperText: isError && error,
          },
          actionBar: { actions: ['clear'] },
        }}
      />
    </LocalizationProvider>
  );
}
