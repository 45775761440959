import mutasiFormModel from './mutasiFormModel';

const {
  formField: {
    kodePegawai,
    dariKantor,
    keKantor,
    tglMulai,
    tglAkhir,
    keterangan
  }
} = mutasiFormModel;

export default {
  [kodePegawai.name]: '',
  [dariKantor.name]: '',
  [keKantor.name]: '',
  [tglMulai.name]: '',
  [tglAkhir.name]: '',
  [keterangan.name]: ''
};
