import * as Yup from 'yup';
import promosiFormModel from './promosiFormModel';

const {
  formField: {
    kodePegawai,
    dariJabatan,
    keJabatan,
    tglMulai
  }
} = promosiFormModel;

export default Yup.object({
  [kodePegawai.name]: Yup.string().required(`${kodePegawai.requiredErrorMsg}`),
  [dariJabatan.name]: Yup.string().required(`${dariJabatan.requiredErrorMsg}`),
  [keJabatan.name]: Yup.string().required(`${keJabatan.requiredErrorMsg}`),
  [tglMulai.name]: Yup.string().required(`${tglMulai.requiredErrorMsg}`)
});
