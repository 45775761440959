import React, { useContext, useState } from 'react';
import {
  Stack,
  Grid,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { InputField, DatePickerField, AutocompleteField, SelectField, UploadField } from '../../../components/form-fields';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';

import validationSchema from './form-model/validationSchema';
import ReqCutiFormModel from './form-model/reqCutiFormModel';
import formInitialValues from './form-model/formInitialValues';

export default function ReqCutiForm({data, dropDown, handleCloseModal, handleUpdateTable}) {
  const notificationCtx = useContext(NotificationContext);
  const pegawai = (dropDown || []).filter(x => x.kategori === 'pegawai');
  const { formId, formField } = ReqCutiFormModel;
  const { kodePegawai, dariTanggal, saldo, hari, khusus, attachment, keterangan } = formField;
  const [cutiKhusus, setCutiKhusus] = useState(data?.khusus ? data?.khusus : 'tidak');
  const { REACT_APP_S3_URL } = process.env;
  const [maxHari, setMaxHari] = useState(data?.saldo || 0);
  const twoWeeksFromNow = new Date();
  twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);

  const _handleInitialValues = data !== undefined ?  {
    kodePegawai: data.kodePegawai,
    dariTanggal: data.dariTanggal,
    saldo: data.saldo,
    hari: data.hari,
    khusus: data.khusus,
    attachment: data.attachment,
    keterangan: data.keterangan
  } : formInitialValues

  const cutiKhususDropdown = [
    {
      value: 'ya',
      label: 'Ya'
    },
    {
      value: 'tidak',
      label: 'Tidak'
    },
  ];

  const handleMessage = async (params) => {
    const success = params?.success;

    if (success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: params?.message
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.message
      });
    }
  };

  const validateBase64Image = (base64String) => {
    const ALLOWED_FORMATS = ['jpeg', 'png', 'jpg'];
  
    // Check if string is base64
    if (!base64String.startsWith('data:image/')) {
      return { isValid: false, message: 'Invalid image format' };
    }
  
    // Extract format
    const format = base64String.substring(11, base64String.indexOf(';base64,'));
    if (!ALLOWED_FORMATS.includes(format)) {
      return { isValid: false, message: 'Unsupported image format' };
    }
  
    return { isValid: true, message: 'Valid image' };
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const { kodePegawai, dariTanggal, hari, khusus, attachment, keterangan } = values;
    const baseUrl = new URL(REACT_APP_S3_URL).origin;
    const params = {
      id: data?._id,
      kodePegawai,
      dariTanggal,
      hari,
      khusus,
      attachment,
      keterangan
    };

    if (!attachment.startsWith(baseUrl)) {
      if (attachment !== '') {
        const { isValid, message } = validateBase64Image(attachment);
    
        // Show error notification if the image is not valid
        if (!isValid) {
          notificationCtx.setup({
            severity: 'error',
            title: 'Error',
            message
          });
    
          return;
        }
      }
    }

    const endpoint = data?._id
      ? axiosPrivate.put(`/req-cuti/${data._id}`, params)
      : axiosPrivate.post(`/req-cuti`, params);

    try {
      const response = await endpoint;
      const { result } = response?.data;

      handleUpdateTable(result);
      handleMessage(response?.data);

      if (!data?._id) {
        resetForm();
      }
    } catch (error) {
      handleMessage(error?.response?.data);
    } finally {
      setSubmitting(false);
    }
  };

  const onSetValue = (value, setFieldValue) => {
    const selectedPegawai = pegawai.find(x => x.value === value);
    const saldo = selectedPegawai?.saldo

    if(saldo) {
      setFieldValue('saldo', saldo);
      setMaxHari(saldo);
    } else {
      setFieldValue('saldo', '');
    }
  }

  return (
    <Formik
      initialValues={_handleInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <AutocompleteField
                name={kodePegawai.name}
                label={kodePegawai.label}
                data={pegawai}
                onSetValue={(value) => onSetValue(value, setFieldValue)}
                readOnly={!!data?.kodePegawai}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DatePickerField
                name={dariTanggal.name}
                label={dariTanggal.label}
                format="dd/MM/yyyy"
                // minDate={twoWeeksFromNow}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SelectField
                name={khusus.name}
                label={khusus.label}
                data={cutiKhususDropdown}
                onChange={(event) => setCutiKhusus(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name={saldo.name}
                label={saldo.label}
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name={hari.name}
                label={hari.label}
                maxValue={cutiKhusus === 'ya' ? Infinity : maxHari}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <UploadField
                id={attachment.id}
                name={attachment.name}
                label={attachment.label}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name={keterangan.name}
                label={keterangan.label}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseModal}
            >
              Kembali
            </Button>
            <LoadingButton type="submit" loading={isSubmitting} variant="contained">
              Simpan
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
