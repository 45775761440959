import { Container, Typography, Stack } from '@mui/material';
import { KantorForm } from '../../../sections/@dashboard/kantor';

const AddKantorPage = ({handleCloseModal, handleUpdateTable}) => (
  <>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Tambah Kantor
        </Typography>
      </Stack>
      <KantorForm
        handleCloseModal={handleCloseModal}
        handleUpdateTable={handleUpdateTable}
      />
    </Container>
  </>
);
  
export default AddKantorPage;
