import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ title, subheader, chartLabels, chartData, ...other }) {
  const chartOptions = useChart({
    colors: ['#1c5fc5', '#66DA26', '#f44336'],
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: {
      type: 'datetime', // Use 'datetime' type for handling dates
      categories: chartLabels.map(date => {
        // console.log(date);
        const currentDate = new Date(date).getTime()
        // currentDate.setDate(currentDate.getDate());

        // Format the date as "MM/DD/YYYY"
        // const month = String(currentDate.getMonth()).padStart(2, '0');
        // const day = String(currentDate.getDate()).padStart(2, '0');
        // const year = currentDate.getFullYear();

        // console.log(currentDate);

        return currentDate;
      }),
      labels: {
        datetimeFormatter: {
          year: 'yyyy', // Format the year part of the date
          month: 'MMM', // Format the month part of the date (e.g., Jan, Feb)
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} pegawai`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
