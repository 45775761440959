import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { axiosPrivate } from '../../../common/axiosPrivate';
import { PegawaiForm } from '../../../sections/@dashboard/pegawai';

const AddPegawaiPage = ({handleUpdateTable}) => {
  const [dropDown, setDropDown] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  
  useEffect(() => {
    setPageLoading(true);
    axiosPrivate.get('pegawai/dropdown/list')
    .then((response) => {
      const data = response?.data;
      
      setDropDown(data);
    })
    .catch((error) => console.log(error))
    .finally(() => setPageLoading(false));
  }, []);

  return (
    <>
      <Helmet>
        <title> Pegawai | Sip App </title>
      </Helmet>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Tambah Pegawai
            </Typography>
          </Stack>
          <PegawaiForm
            dropDown={dropDown}
            handleUpdateTable={handleUpdateTable}
          />
        </Container>
        )}
    </>
  );
}
  
export default AddPegawaiPage;
