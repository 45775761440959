export default {
  formId: 'mutasiForm',
  formField: {
    kodePegawai: {
      name: 'kodePegawai',
      label: 'Pegawai*',
      requiredErrorMsg: 'Pegawai harus dipilih'
    },
    dariKantor: {
      name: 'dariKantor',
      label: 'Dari Kantor*',
      requiredErrorMsg: 'Dari kantor harus dipilih'
    },
    keKantor: {
      name: 'keKantor',
      label: 'Ke Kantor*',
      requiredErrorMsg: 'Ke kantor harus dipilih'
    },
    tglMulai: {
      name: 'tglMulai',
      label: 'Tgl Mulai*',
      requiredErrorMsg: 'Tanggal mulai harus dipilih'
    },
    tglAkhir: {
      name: 'tglAkhir',
      label: 'Tgl Akhir',
    },
    keterangan: {
      name: 'keterangan',
      label: 'Keterangan'
    }
  }
};
