import axios from "axios";
import Cookies from 'js-cookie';
import { memoizedRefreshToken } from "./refreshToken";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  async (config) => {
    const token = Cookies.get("api_token");

    if (token) {
      config.headers = {
        ...config.headers,
        'x-access-token': token,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if ((error?.response?.status === 401 || error?.response?.status === 403) && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.access_token) {
        config.headers = {
          ...config.headers,
          'x-access-token': result.access_token,
        };

        // Retry the original request with the updated token
        return axios(config);
      }
      // Token refresh failed or result is null, redirect to login page
      window.location.href = '/login'; // Adjust the URL as needed
    }

    return Promise.reject(error);
  }
);

export const axiosPrivate = axios;
