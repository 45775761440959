import { useState } from 'react';
import { Grid } from '@mui/material';
import { InputField, SelectField, DatePickerField } from '../../../../components/form-fields';

const statusPegawais = [
  { value: 'Magang', label: 'Magang' },
  { value: 'PKWT', label: 'PKWT' },
  { value: 'PKWTT', label: 'PKWTT' },
  { value: 'Off-Boarding', label: 'Off-Boarding' }
];

const integritasDropDown = [
  { value: 'Iya', label: 'Iya' },
  { value: 'Tidak', label: 'Tidak' }
];

export default function KepegawaianForm(props) {
  const {
    formField: {
      tglMasuk,
      tglKeluar,
      kantor,
      jabatan,
      integritas,
      statusPegawai,
      tglStatus,
      tglMulaiKontrak,
      tglAkhirKontrak,
      kontrakMagangKe,
      kontrakPkwtKe,
      notes
    }
  } = props;
  const { dropDown } = props;

  const kantors = (dropDown || []).filter(x => x.kategori === 'kantor');
  const jabatans = (dropDown || []).filter(x => x.kategori === 'jabatan');

  const [kontrak, setKontrak] = useState(['PKWT', 'Magang'].includes(props.statusPegawai));
  const [magang, setMagang] = useState(props.statusPegawai === 'Magang');
  const [pkwt, setPkwt] = useState(props.statusPegawai === 'PKWT');
  const [pkwtt, setPkwtt] = useState(props.statusPegawai === 'PKWTT');

  const handleChangeStatus = (e) => {
    const { value } = e.target;
    setKontrak(['PKWT', 'Magang'].includes(value));
    setMagang(value === 'Magang');
    setPkwt(value === 'PKWT');
    setPkwtt(value === 'PKWTT');
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <DatePickerField
          name={tglMasuk.name}
          label={tglMasuk.label}
          format="dd/MM/yyyy"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerField
          name={tglKeluar.name}
          label={tglKeluar.label}
          format="dd/MM/yyyy"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={kantor.name}
          label={kantor.label}
          data={kantors}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={jabatan.name}
          label={jabatan.label}
          data={jabatans}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={statusPegawai.name}
          label={statusPegawai.label}
          data={statusPegawais}
          onChange={handleChangeStatus}
          fullWidth
        />
      </Grid>

      {pkwtt && (
        <Grid item xs={12} sm={6}>
          <DatePickerField
            name={tglStatus.name}
            label={tglStatus.label}
            format="dd/MM/yyyy"
            fullWidth
          />
        </Grid>
      )}

      {kontrak && (
        <>
          <Grid item xs={12} sm={6}>
            {magang && (
              <InputField
                name={kontrakMagangKe.name}
                label={kontrakMagangKe.label}
                fullWidth
              />
            )}
            {pkwt && (
              <InputField
                name={kontrakPkwtKe.name}
                label={kontrakPkwtKe.label}
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerField
              name={tglMulaiKontrak.name}
              label={tglMulaiKontrak.label}
              format="dd/MM/yyyy"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerField
              name={tglAkhirKontrak.name}
              label={tglAkhirKontrak.label}
              format="dd/MM/yyyy"
              fullWidth
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} sm={6}>
        <SelectField
          name={integritas.name}
          label={integritas.label}
          data={integritasDropDown}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <InputField
          name={notes.name}
          label={notes.label}
          multiline
          rows={3}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
