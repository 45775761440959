import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  }

  if(item.submenu) {
    return (
      <>
        <StyledNavItem
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
          onClick={handleClick}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {open ? <ExpandLess /> : <ExpandMore />}
          {info && info}
        </StyledNavItem>
        
        {item.submenu && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.submenu.map((submenuItem, index) => (
                <StyledNavItem
                  key={index}
                  component={RouterLink}
                  to={submenuItem.path}
                  sx={{
                    '&.active': {
                      color: 'text.primary',
                      bgcolor: 'action.selected',
                      fontWeight: 'fontWeightBold',
                    },
                  }}
                >
                  <StyledNavItemIcon>
                    {submenuItem.icon && submenuItem.icon}
                  </StyledNavItemIcon>
                  <ListItemText
                    disableTypography
                    primary={submenuItem.title}
                  />
                  {submenuItem.info && submenuItem.info}
                </StyledNavItem>
              ))}
            </List>
          </Collapse>
        )}
      </>
    );    
  }

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  )
  
}
