import dokumenFormModel from './dokumenFormModel';

const {
  formField: {
    dokumen,
    nama,
    keterangan,
    penerima,
    penyerah,
    tglTerima,
    tglSerah
  }
} = dokumenFormModel;

export default {
  [dokumen.name]: '',
  [nama.name]: '',
  [keterangan.name]: '',
  [penerima.name]: '',
  [penyerah.name]: '',
  [tglTerima.name]: '',
  [tglSerah.name]: ''
};
