import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Stack,
  Card,
  Container,
  IconButton,
  ButtonGroup,
  Button,
  Modal,
  Box,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import Iconify from '../../../components/iconify';
import EditSaldoCutiPage from './EditSaldoCutiPage';
import AddSaldoCutiPage from './AddSaldoCutiPage';
import { getCurrentDate } from "../../../utils/formatDate";
import NotificationContext from '../../../context/notificationContext';
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function SaldoCutiPage() {
  const navigate = useNavigate();
  const notificationCtx = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedId, setSelectedId] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
     name: "namaPegawai",
     label: "Nama",
     options: {
      filter: true,
      sort: true
     }
    },
    {
     name: "kodePegawai",
     label: "Kode",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "tahun",
      label: "Tahun",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "saldo",
      label: "Saldo",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "_id",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customHeadRender: () => null,
        customBodyRender: (value) => (
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleOpenModal(1, value)}
          >
            <Iconify icon={'eva:edit-fill'} />
            Edit
          </Button>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => deleteLoading ? (
      <CircularProgress
        sx={{
          color: '#637381',
          mr: 2
        }}
        size={20}
      />
    ) : (
      <DeleteIcon
        size={20}
        sx={{
          color: '#637381',
          cursor: 'pointer',
          mr: 2
        }}
        onClick={() => handleDelete(selectedRows, displayData, setSelectedRows)}
      />
    ),
    downloadOptions: {
      filename: `saldo-cuti-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  const handleOpenModal = (content, id) => {
    setSelectedId(id);
    setModalContent(content);
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleUpdateTable = (newData) => {
    const index = data.findIndex(item => item._id === newData._id);
  
    if (index !== -1) {
      data[index] = { ...data[index], ...newData };
      setData([...data]);
    } else {
      setData([newData, ...data]);
    }
  };

  const handleDelete = (selectedRows, displayData, setSelectedRows) => {
    setDeleteLoading(true);

    const ids = selectedRows.data.map(d => displayData[d.index].data[0]);

    axiosPrivate.post('/saldo-cuti/destroy', {'data' : ids})
    .then((response) => {
      const count = response?.data?.deletedCount;

      if(count > 0) {
        ids.map((item) => setData(prev => prev.filter(e => e._id !== item)));
        setSelectedRows([]);
      }

      if(count > 0) {
        setData(prev => prev.filter(e => e._id !== ids))
      }

      handleMessage(response);
    })
    .catch((error) => handleMessage(error?.response))
    .finally(() => setDeleteLoading(false));
  }

  const handleMessage = async (params) => {
    const count = params?.data?.deletedCount;

    if(count > 0) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: "Data Berhasil Dihapus."
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: "Data Gagal Dihapus!"
      });
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      // Fetch data from the API
      const response = await axiosPrivate.get('/saldo-cuti/download/template');
      const { data } = response;

      // Get the current year
      const currentYear = new Date().getFullYear();
  
      // Check if data exists and format it as CSV
      let csvContent = "data:text/csv;charset=utf-8,";
      // Add CSV headers
      csvContent += "Kode Pegawai,Nama Pegawai,Tahun,Saldo\n";
  
      // Loop through each data item and append it to the CSV content
      data.forEach(item => {
        const nama = item.nama.replace(/,/g, '.');
        const row = `${item.kodePegawai},${nama},${currentYear},${item.saldo}`;
        csvContent += `${row  }\n`;
      });
  
      // Encode the CSV content
      const encodedUri = encodeURI(csvContent);
  
      // Create a link element, use it to download the CSV, and remove it after clicking
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `Template-Saldo-Cuti-${getCurrentDate('-')}.csv`);
      document.body.appendChild(link); // Required for FF
  
      link.click(); // This will download the CSV file
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error('Error fetching template data:', error);
      // Handle error or notify user here
    }
  };
  
  // Fetch Api
  useEffect(() => {
    axiosPrivate.get('saldo-cuti')
    .then((response) => setData(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, []);

  return (
    <>
      <Helmet>
        <title> List Saldo Cuti | Sip App </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 2 }}>
          <ButtonGroup variant="contained" color="primary">
            <Button
              variant="contained"
              color="success"
              onClick={handleDownloadTemplate} 
              startIcon={<Iconify icon="eva:file-text-outline" />}
            >
              Download Template Import
            </Button>
            <Button
              onClick={() => navigate("../kepegawaian/cuti/saldo-cuti/import")} 
              startIcon={<Iconify icon="eva:upload-fill" />}
            >
              Import
            </Button>
            <Button
              onClick={() => handleOpenModal(0)}
              variant='contained'
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Tambah
            </Button>
          </ButtonGroup>
        </Stack>
        <Card>
          <MUIDataTable
            title="List Saldo Cuti"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        hideBackdrop
        open={modalOpen}
        onClose={handleCloseModal}
        style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {modalContent === 0 ? (
            <AddSaldoCutiPage
              handleCloseModal={handleCloseModal}
              handleUpdateTable={handleUpdateTable}
            />
          ) : (
            <EditSaldoCutiPage
              id={selectedId}
              handleCloseModal={handleCloseModal}
              handleUpdateTable={handleUpdateTable}
            />
          )}
        </>
      </Modal>
    </>
  );
}
