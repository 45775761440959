export default {
  formId: 'kantorForm',
  formField: {
    kode: {
      name: 'kode',
      label: 'Kode*',
      requiredErrorMsg: 'Kode harus diisi'
    },
    nama: {
      name: 'nama',
      label: 'Nama*',
      requiredErrorMsg: 'Nama harus diisi'
    }
  }
};
