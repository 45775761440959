import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Box, Typography, CircularProgress } from '@mui/material';
import { axiosPrivate } from '../common/axiosPrivate';
// components
// import Iconify from '../components/iconify';
// sections
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppConversionRates,
  AppJabatan
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [pageLoading, setPageLoading] = useState(true);
  const [total, setTotal] = useState({});
  const {
    pegawaiStatusAktif,
    pegawaiStatusTidakAktif,
    pegawaiPria,
    pegawaiWanita,
    aktifCounts,
    groupByKantor,
    groupByJabatanGrup,
    groupByKotaKab,
    groupByLamaKerja,
    pegawaiKontrakTigaBulan,
    pegawaiKontrakEnamBulan
  } = total;

  useEffect(() => {
    // Set page loading state to true initially
    setPageLoading(true);
  
    // Fetch data from the 'dashboard' endpoint using axiosPrivate
    axiosPrivate
      .get('dashboard')
      .then((response) => {
        // Update 'total' state with the response data
        setTotal(response?.data);
        setPageLoading(false);
      })
      .catch((error) => {
        // Log any errors in the console
        console.log(error?.response);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Sip App </title>
      </Helmet>

      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hai, Selamat Datang Kembali
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Pegawai Aktif" total={pegawaiStatusAktif} icon={'ant-design:bulb-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Kontrak Dibawah 3 Bulan" total={pegawaiKontrakTigaBulan} color="info" icon={'ant-design:pushpin-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Kontrak Dibawah 6 Bulan" total={pegawaiKontrakEnamBulan} color="warning" icon={'ant-design:rocket-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Pegawai Keluar" total={pegawaiStatusTidakAktif} color="error" icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Pegawai"
              subheader="Pertumbuhan Pegawai"
              chartLabels={aktifCounts?.label}
              chartData={[
                {
                  name: 'Pegawai Aktif',
                  type: 'area',
                  fill: 'gradient',
                  data: aktifCounts?.total,
                },
                {
                  name: 'Masuk',
                  type: 'area',
                  fill: 'gradient',
                  data: aktifCounts?.masuk,
                },
                {
                  name: 'Keluar',
                  type: 'area',
                  fill: 'gradient',
                  data: aktifCounts?.keluar,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Jenis Kelamin"
              chartData={[
                { label: 'Laki-Laki', value: pegawaiPria },
                { label: 'Perempuan', value: pegawaiWanita},
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.warning.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Kantor"
              subheader="Posisi Pegawai Berdasarkan Kantor"
              chartData={groupByKantor}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Tempat Tinggal"
              chartData={groupByKotaKab}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppJabatan
              title="Jabatan"
              subheader="Posisi Pegawai Berdasarkan Jabatan"
              chartData={groupByJabatanGrup}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Lama Kerja"
              chartData={groupByLamaKerja}
            />
          </Grid>
        </Grid>
      </Container>
      ) }
    </>
  );
}
