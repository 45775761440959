import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import DokumenForm from '../../../../sections/@dashboard/pegawai/DokumenForm';
import { axiosPrivate } from '../../../../common/axiosPrivate';

const AddDokPegawaiPage = ({idPegawai, handleCloseModal, handleUpdateTable}) => {
  const [pegawai, setPegawai] = useState({});
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    axiosPrivate.get(`/pegawai/${idPegawai}`)
    .then((response) => {
      const data = response?.data;

      setPegawai({
        _id: data?._id,
        nama: data?.nama,
        kodePegawai: data?.kodePegawai,
      });
    })
    .catch((error) => console.log(error?.response))
    .finally(() => setPageLoading(false));
  }, [idPegawai]);

  return (
    <>
      <Helmet>
        <title> Dokumen | Sip App </title>
      </Helmet>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Tambah Dokumen {pegawai?.nama}
            </Typography>
          </Stack>
          <DokumenForm
            pegawai={{
              idPegawai: pegawai?._id,
              kodePegawai: pegawai?.kodePegawai
            }}
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </Container>
      )}
    </>
    );
}
  
export default AddDokPegawaiPage;
