import kecamatanFormModel from './kecamatanFormModel';

const {
  formField: {
    kode,
    nama
  }
} = kecamatanFormModel;

export default {
  [kode.name]: '',
  [nama.name]: ''
};
