const avatars = [
  {
    value: 'avatar_default',
    label: 'Default',
  },
  {
    value: 'avatar_1',
    label: 'Avatar 1',
  },
  {
    value: 'avatar_2',
    label: 'Avatar 2',
  },
  {
    value: 'avatar_3',
    label: 'Avatar 3',
  },
  {
    value: 'avatar_4',
    label: 'Avatar 4',
  },
  {
    value: 'avatar_5',
    label: 'Avatar 5',
  },
  {
    value: 'avatar_6',
    label: 'Avatar 6',
  },
  {
    value: 'avatar_7',
    label: 'Avatar 7',
  },
  {
    value: 'avatar_8',
    label: 'Avatar 8',
  },
  {
    value: 'avatar_9',
    label: 'Avatar 9',
  },
  {
    value: 'avatar_10',
    label: 'Avatar 10',
  },
  {
    value: 'avatar_11',
    label: 'Avatar 11',
  },
  {
    value: 'avatar_12',
    label: 'Avatar 12',
  },
  {
    value: 'avatar_13',
    label: 'Avatar 13',
  },
  {
    value: 'avatar_14',
    label: 'Avatar 14',
  },
  {
    value: 'avatar_15',
    label: 'Avatar 15',
  },
  {
    value: 'avatar_16',
    label: 'Avatar 16',
  },
  {
    value: 'avatar_17',
    label: 'Avatar 17',
  },
  {
    value: 'avatar_18',
    label: 'Avatar 18',
  },
  {
    value: 'avatar_19',
    label: 'Avatar 19',
  },
  {
    value: 'avatar_20',
    label: 'Avatar 20',
  },
  {
    value: 'avatar_21',
    label: 'Avatar 21',
  },
  {
    value: 'avatar_22',
    label: 'Avatar 22',
  },
  {
    value: 'avatar_23',
    label: 'Avatar 23',
  },
  {
    value: 'avatar_24',
    label: 'Avatar 24',
  },
];

export default avatars;
