import * as Yup from 'yup';
import userFormModel from './userFormModel';

const {
  formField: {
    firstName,
    lastName,
    email,
    role,
    kantor,
    status
  }
} = userFormModel;

export default Yup.object({
  [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
  [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
  [email.name]: Yup.string().email().required(`${email.requiredErrorMsg}`),
  [role.name]: Yup.string().required(`${role.requiredErrorMsg}`),
  [kantor.name]: Yup.string().required(`${kantor.requiredErrorMsg}`),
  [status.name]: Yup.string().required(`${status.requiredErrorMsg}`)
});
