import * as Yup from 'yup';
import absenFormModel from './absenFormModel';

const {
  formField: {
    tanggal,
    kodePegawai,
    jenis,
    keterangan
  }
} = absenFormModel;

export default Yup.object({
  [tanggal.name]: Yup.string().required(`${tanggal.requiredErrorMsg}`),
  [kodePegawai.name]: Yup.string().required(`${kodePegawai.requiredErrorMsg}`),
  [jenis.name]: Yup.string().required(`${jenis.requiredErrorMsg}`),
  [keterangan.name]: Yup.string().required(`${keterangan.requiredErrorMsg}`)
});
