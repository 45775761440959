export default {
  formId: 'saldoCutiForm',
  formField: {
    kodePegawai: {
      name: 'kodePegawai',
      label: 'Pegawai*',
      requiredErrorMsg: 'Pegawai harus dipilih'
    },
    tahun: {
      name: 'tahun',
      label: 'Tahun',
      requiredErrorMsg: 'Tahun harus dipilih'
    },
    saldo: {
      name: 'saldo',
      label: 'Saldo',
      requiredErrorMsg: 'Saldo harus dipilih'
    }
  }
};
