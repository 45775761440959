export default {
    formId: 'dokumenAllForm',
    formField: {
      dokumen: {
        name: 'dokumen',
        label: 'Dokumen*',
        requiredErrorMsg: 'Dokumen harus diisi'
      },
      pegawai: {
        name: 'pegawai',
        label: 'Pegawai*',
        requiredErrorMsg: 'Pegawai harus diisi'
      },
      nama: {
        name: 'nama',
        label: 'Nama Dokumen*',
        requiredErrorMsg: 'Nama harus diisi'
      },
      keterangan: {
        name: 'keterangan',
        label: 'Keterangan'
      },
      penerima: {
        name: 'penerima',
        label: 'Penerima*',
        requiredErrorMsg: 'Penerima harus diisi'
      },
      penyerah: {
        name: 'penyerah',
        label: 'Penyerah',
      },
      tglTerima: {
        name: 'tglTerima',
        label: 'Tgl Terima*',
        requiredErrorMsg: 'Tgl Terima harus diisi'
      },
      tglSerah: {
        name: 'tglSerah',
        label: 'Tgl Serah',
      }
    }
  };
  