import { Popover, MenuItem } from '@mui/material';
import Iconify from '../../../components/iconify';

function TablePopover({ open, anchorEl, handleCloseMenu, handleOpenModal }) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      slotProps={{
        paper: {
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }
      }}
    >
      <MenuItem onClick={() => handleOpenModal(1)}>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Ubah
      </MenuItem>
      <MenuItem onClick={() => handleOpenModal(2)}>
        <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
        Detil
      </MenuItem>
    </Popover>
  );
}

export default TablePopover;
