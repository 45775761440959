import * as Yup from 'yup';
import processCutiFormModel from './processCutiFormModel';

const {
  formField: {
    dariTanggal,
    hari,
    keterangan
  }
} = processCutiFormModel;

export default Yup.object({
  [dariTanggal.name]: Yup.date().required(`${dariTanggal.requiredErrorMsg}`),
  [hari.name]: Yup.number()
                .typeError('Hari harus number')
                .required(`${hari.requiredErrorMsg}`),
  [keterangan.name]: Yup.string().required(`${keterangan.requiredErrorMsg}`)
});
