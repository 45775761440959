export default {
  formId: 'processCutiForm',
  formField: {
    dariTanggal: {
      name: 'dariTanggal',
      label: 'Dari Tanggal*',
      requiredErrorMsg: 'Dari Tanggal harus dipilih'
    },
    hari: {
      name: 'hari',
      label: 'Jumlah Hari*',
      requiredErrorMsg: 'Jumlah Hari harus dipilih'
    },
    keterangan: {
      name: 'keterangan',
      label: 'Keterangan*',
      requiredErrorMsg: 'Ketrangan harus diisi'
    }
  }
};
