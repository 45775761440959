export default {
  formId: 'userForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'Nama Depan*',
      requiredErrorMsg: 'Nama depan harus diisi'
    },
    lastName: {
      name: 'lastName',
      label: 'Nama Belakang*',
      requiredErrorMsg: 'Nama belakang harus diisi'
    },
    email: {
      name: 'email',
      label: 'Email*',
      requiredErrorMsg: 'Email harus diisi'
    },
    avatar: {
      name: 'avatar',
      label: 'Avatar'
    },
    role: {
      name: 'role',
      label: 'Role*',
      requiredErrorMsg: 'Role harus diisi'
    },
    kantor: {
      name: 'kantor',
      label: 'Kantor*',
      requiredErrorMsg: 'Kantor harus diisi'
    },
    password: {
      name: 'password',
      label: 'Password'
    },
    repassword: {
      name: 'repassword',
      label: 'Ulangi Password'
    },
    status: {
      name: 'status',
      label: 'Status*',
      requiredErrorMsg: 'Status harus diisi'
    },
  }
};
