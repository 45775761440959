import { useState, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import AuthContext from '../../../context/authContext';
import NotificationContext from '../../../context/notificationContext';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const notificationCtx = useContext(NotificationContext);
  const {loading, login} = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const email = useRef(null);
  const password = useRef(null);

  const handleClick = async () => {
    const payload = {
      email: email.current.value,
      password: password.current.value,
    };
    const result = await login(payload);

    if(result.status !== 200 ) {
      handleMessage(result);
    } else {
      navigate('/');
    }
  };

  const handleMessage = async (params) => {
    const messageText = params?.data;

    notificationCtx.setup({
      severity: 'error',
      title: 'Error',
      message: messageText
    });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          inputRef={email}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          inputRef={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
