import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
// @mui
import {
  Card,
  Container,
  Grid,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { id } from "date-fns/locale";
import { format, parseISO } from 'date-fns';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import { getCurrentDate, customDate } from "../../../utils/formatDate";
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function LapPromosiMutasiPage() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
     name: "namaPegawai",
     label: "Nama",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "kodePegawai",
     label: "Kode",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "dariJabatan",
      label: "Jabatan Lama",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "keJabatan",
      label: "Jabatan Baru",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "dariKantor",
      label: "Kantor Lama",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "keKantor",
      label: "Kantor Tujuan",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "keterangan",
      label: "Keterangan",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "tglMulai",
      label: "Tgl Efektif",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => Object.prototype.toString.call(value) ? format(parseISO(value), 'dd/MM/yyyy') : format(new Date(value), 'dd/MM/yyyy'),
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return [`Efektif Dari: ${startDate}`, `Sampai Dengan: ${enDate}`];
            } if (v[0]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');

              return `Efektif Dari: ${startDate}`;
            } if (v[1]) {
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return `Sampai Dengan: ${enDate}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          names: [],
          logic(date, filters) {
            const formated = new Date(customDate(date));

            if (filters[0] && filters[1]) {
              return !(formated >= filters[0] && formated <= filters[1]);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <>
              <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Efektif Dari"
                      value={filterList[index][0] || null}
                      onChange={date => {
                        filterList[index][0] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Sampai Dengan"
                      value={filterList[index][1] || null}
                      onChange={date => {
                        filterList[index][1] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </>
          ),
        },
      }
    },
    {
      name: "masaJabatan",
      label: "Masa Jabatan",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          if (value?.years > 0) {
            return `${value.days} Hari ${value.months} Bulan ${value.years} Tahun`;
          } if (value?.months > 0) {
            return `${value.days} Hari ${value.months} Bulan`;
          } 

          return `${value.days} Hari`;
        }
      }
    },
    {
      name: "berjalan",
      label: "Berjalan",
      options: {
        filter: true,
        sort: false,
        customBodyRender : (value) => value === true ? 'Ya' : 'Tidak'
      }
    },
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `laporan-promosi-mutasi-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  // Fetch Api
  useEffect(() => {
    axiosPrivate.get('laporan/promosi-mutasi')
    .then((response) => setData(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, []);

  return (
    <>
      <Helmet>
        <title> Laporan Promosi dan Mutasi | Sip App </title>
      </Helmet>

      <Container>
        <Card>
          <MUIDataTable
            title="Laporan Promosi dan Mutasi"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>
    </>
  );
}
