import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { CanvasSignature } from '../../../components/canvas';

function PageSignaturePegawai({handleSetValue, handleCloseModal}) {
  return (
    <>
      <Container maxWidth="sm" style={{ marginTop: '32px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Buat Tanda Tangan
          </Typography>
        </Stack>
        <CanvasSignature
          handleSetValue={handleSetValue}
          handleCloseModal={handleCloseModal}
        />
      </Container>
    </>
  );
}

export default PageSignaturePegawai;
