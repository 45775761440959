import React, { useContext } from 'react';
import moment from 'moment';
import {
  Grid,
  Button,
  Stack
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { InputField, SelectField } from '../../../components/form-fields';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';
import AuthContext from '../../../context/authContext';

import validationSchema from './form-model/validationSchema';
import jabatanFormModel from './form-model/jabatanFormModel';
import formInitialValues from './form-model/formInitialValues';

const grups = [
  {
    value: 'Pusat',
    label: 'Pusat'
  },
  {
    value: 'Manajer',
    label: 'Manajer'
  },
  {
    value: 'SPI',
    label: 'SPI'
  },
  {
    value: 'Kepala Cabang',
    label: 'Kepala Cabang'
  },
  {
    value: 'Wakil Kepala Cabang',
    label: 'Wakil Kepala Cabang'
  },
  {
    value: 'Staf Lapang',
    label: 'Staf Lapang'
  },
  {
    value: 'Staf Admin',
    label: 'Staf Admin'
  },
  {
    value: 'Staf Umum',
    label: 'Staf Umum'
  },
  {
    value: 'Magang',
    label: 'Magang'
  }
];

export default function JabatanForm({data, handleCloseModal, handleUpdateTable}) {
  const { user } = useContext(AuthContext);
  const notificationCtx = useContext(NotificationContext);
  const { formId, formField } = jabatanFormModel;
  const { kode, nama, grup } = formField;

  const _handleInitialValues = data !== undefined ?  {
      kode: data.kode,
      nama: data.nama,
      grup: data.grup
    } : formInitialValues

  // console.log(formInitialValues);

  const handleMessage = (params) => {
    if(params?.status === 200) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: data?._id ? 'Data Berhasil Diubah.' : 'Data Berhasil Dibuat.'
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.response.data
      })
    }
  }

  const _handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const params = data?._id ? {
      kode: values?.kode,
      nama: values?.nama,
      grup: values?.grup,
      dibuatOleh: user?._id,
      tglUbah: moment().format('YYYY-MM-DDTHH:mm:ss')
    } : {
      kode: values?.kode,
      nama: values?.nama,
      grup: values?.grup,
      dibuatOleh: user?._id,
      tglBuat: moment().format('YYYY-MM-DDTHH:mm:ss')
    }
    const endpoint = data ? axiosPrivate.put(`/jabatan/${data._id}`, params) : axiosPrivate.post(`/jabatan`, params);

    await endpoint
    .then((response) => {
      const { result } = response?.data;

      handleUpdateTable(result);
      handleMessage(response);

      if(!data?._id) {
        resetForm()
      }
    })
    .catch((error) => handleMessage(error))
    .finally(() => {
      setSubmitting(false);
    });
  }

  return (
    <>
      <Formik
        initialValues={_handleInitialValues}
        validationSchema={validationSchema}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form id={formId}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <InputField
                  name={kode.name}
                  label={kode.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputField
                  name={nama.name}
                  label={nama.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectField
                  name={grup.name}
                  label={grup.label}
                  data={grups}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Stack
              direction="row"
              justifyContent="end"
              mt={2}
              spacing={1}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseModal}
              >
                Kembali
              </Button>
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
              >
                Simpan
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
}
