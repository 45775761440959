export default {
  formId: 'pegawaiKeluarForm',
  formField: {
    kodePegawai: {
      name: 'kodePegawai',
      label: 'Pegawai*',
      requiredErrorMsg: 'Pegawai harus dipilih'
    },
    tglKeluar: {
      name: 'tglKeluar',
      label: 'Tanggal Keluar*',
      requiredErrorMsg: 'Tanggal keluar harus dipilih'
    },
    sebab: {
      name: 'sebab',
      label: 'Sebab*',
      requiredErrorMsg: 'Ke kantor harus dipilih'
    },
    keterangan: {
      name: 'keterangan',
      label: 'Keterangan'
    }
  }
};
