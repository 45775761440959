import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useContext, useMemo } from 'react';
// @mui
import {
  Card,
  Container,
  Stack,
  Modal,
  Box,
  CircularProgress,
  IconButton,
  Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from "@mui/icons-material/Close";
import { format, parseISO } from 'date-fns';
import MUIDataTable from "mui-datatables";
import AddJabatanPage from './AddJabatanPage';
import EditJabatanPage from './EditJabatanPage';
import { TableLoader } from '../../../components/table';
import Iconify from '../../../components/iconify';
import { getCurrentDate } from "../../../utils/formatDate";
import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';

export default function JabatanPage() {
  const notificationCtx = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [tableLoading, setTableLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
      name: "kode",
      label: "Kode",
      options: {
       filter: false,
       sort: true
      }
     },
    {
     name: "nama",
     label: "Nama",
     options: {
      filter: false,
      sort: true
     }
    },
    {
      name: "dibuatOleh",
      label: "Dibuat Oleh",
      options: {
        filter: true,
        sort: false,
        filterOptions: {
          fullWidth: true
        }
      }
    },
    {
      name: "tglBuat",
      label: "Tgl Buat",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "tglUbah",
      label: "Tgl Ubah",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
    name: "_id",
    label: "Aksi",
    options: {
      filter: false,
      sort: false,
      customHeadRender: () => null,
      customBodyRender: (value) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleOpenModal(1, value)}
        >
          <Iconify icon={'eva:edit-fill'} />
          Edit
        </Button>
       )
    }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => deleteLoading ? (
      <CircularProgress
        sx={{
          color: '#637381',
          mr: 2
        }}
        size={20}
      />
    ) : (
      <DeleteIcon
        size={20}
        sx={{
          color: '#637381',
          cursor: 'pointer',
          mr: 2
        }}
        onClick={() => handleDelete(selectedRows, displayData, setSelectedRows)}
      />
    ),
    downloadOptions: {
      filename: `mutasi-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    }
  };

  const handleOpenModal = (content, id) => {
    setSelectedId(id);
    setModalContent(content);
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleUpdateTable = (newData) => {
    const index = data.findIndex(item => item._id === newData._id);
  
    if (index !== -1) {
      data[index] = { ...data[index], ...newData };
      setData([...data]);
    } else {
      setData([newData, ...data]);
    }
  };
  

  const handleDelete = (selectedRows, displayData, setSelectedRows) => {
    setDeleteLoading(true);

    const ids = selectedRows.data.map(d => displayData[d.index].data[0]);

    axiosPrivate.post('/jabatan/destroy', {'data' : ids})
    .then((response) => {
      const count = response?.data?.deletedCount;

      if(count > 0) {
        ids.map((item) => setData(prev => prev.filter(e => e._id !== item)));
        setSelectedRows([]);
      }

      if(count > 0) {
        setData(prev => prev.filter(e => e._id !== ids))
      }

      handleMessage(response);
    })
    .catch((error) => handleMessage(error?.response))
    .finally(() => setDeleteLoading(false));
  }

  const handleMessage = async (params) => {
    const count = params?.data?.deletedCount;

    if(count > 0) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: "Data Berhasil Dihapus."
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: "Data Gagal Dihapus!"
      });
    }
  };

  // Fetch Api
  useEffect(() => {
    axiosPrivate.get('jabatan')
    .then((response) => setData(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, []);

  return (
    <>
      <Helmet>
        <title> List Jabatan | Sip App </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 2 }}>
          <Button
            onClick={() => handleOpenModal(0)}
            variant='contained'
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Tambah
          </Button>
        </Stack>
        <Card>
          <MUIDataTable
            title="List Jabatan"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        hideBackdrop
        open={modalOpen}
        onClose={handleCloseModal}
        style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {modalContent === 0 ? (
            <AddJabatanPage
              handleCloseModal={handleCloseModal}
              handleUpdateTable={handleUpdateTable}
            />
          ) : (
            <EditJabatanPage
              id={selectedId}
              handleCloseModal={handleCloseModal}
              handleUpdateTable={handleUpdateTable}
            />
          )}
        </>
      </Modal>
    </>
  );
}
