import React, { useContext } from 'react';
import moment from 'moment';
import {
  Stack,
  Grid,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { InputField, SelectField, DatePickerField, AutocompleteField } from '../../../components/form-fields';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';
import AuthContext from '../../../context/authContext';

import validationSchema from './form-model/validationSchema';
import pegawaiKeluarFormModel from './form-model/pegawaiKeluarFormModel';
import formInitialValues from './form-model/formInitialValues';

const sebabs = [
  {
    value: 'Mengundurkan Diri',
    label: 'Mengundurkan Diri'
  },
  {
    value: 'PHK',
    label: 'PHK'
  },
  {
    value: 'Meninggal',
    label: 'Meninggal'
  }
];

export default function PegawaiKeluarForm({data, dropDown, handleCloseModal, handleUpdateTable}) {
  const { user } = useContext(AuthContext);
  const notificationCtx = useContext(NotificationContext);
  const pegawais = (dropDown || []).filter(x => x.kategori === 'pegawai');
  const { formId, formField } = pegawaiKeluarFormModel;
  const { kodePegawai, tglKeluar, sebab, keterangan } = formField;

  const _handleInitialValues = data !== undefined ?  {
    kodePegawai: data.kodePegawai,
    tglKeluar: data.tglKeluar,
    sebab: data.sebab,
    keterangan: data.keterangan
  } : formInitialValues

  const handleMessage = async (params) => {
    const success = params?.success;

    if (success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: params?.message
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.message
      });
    }
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const params = {
      kodePegawai: values?.kodePegawai,
      tglKeluar: values?.tglKeluar,
      sebab: values?.sebab,
      keterangan: values?.keterangan,
      dibuatOleh: user?._id
    };

    if (data?._id) {
      params._id = data?._id;
      params.tglUbah = moment().format('YYYY-MM-DDTHH:mm:ss');
    } else {
      params.tglBuat = moment().format('YYYY-MM-DDTHH:mm:ss');
    }

    // console.log(JSON.stringify(params));

    const endpoint = data?._id
      ? axiosPrivate.put(`/pegawai-keluar/${data._id}`, params)
      : axiosPrivate.post(`/pegawai-keluar`, params);

    try {
      const response = await endpoint;
      const { result } = response?.data;

      handleUpdateTable(result);
      handleMessage(response?.data);

      if (!data?._id) {
        resetForm();
      }
    } catch (error) {
      handleMessage(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={_handleInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <AutocompleteField
                name={kodePegawai.name}
                label={kodePegawai.label}
                data={pegawais}
                readOnly={!!data?.kodePegawai}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DatePickerField
                name={tglKeluar.name}
                label={tglKeluar.label}
                format="dd/MM/yyyy"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SelectField
                name={sebab.name}
                label={sebab.label}
                data={sebabs}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField name={keterangan.name} label={keterangan.label} fullWidth />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseModal}
            >
              Kembali
            </Button>
            <LoadingButton type="submit" loading={isSubmitting} variant="contained">
              Simpan
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
