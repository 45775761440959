import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { KotaKabupatenForm } from '../../../sections/@dashboard/kota-kabupaten';
import { axiosPrivate } from '../../../common/axiosPrivate';

const EditKotaKabupatenPage = ({id, handleCloseModal, handleUpdateTable}) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [data, setData] = useState(null);


  useEffect(() => {
    setPageLoading(true);
      axiosPrivate.get(`/kotakab/${id}`)
      .then((response) => {
        console.log(response)
        setData(response?.data)
      })
      .catch((error) => console.log(error?.response))
      .finally(() => setPageLoading(false));
  }, [id]);

  return (
    <>
      <Helmet>
        <title> Kota/Kabupaten | Sip App </title>
      </Helmet>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
          <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Ubah Kota/Kabupaten
            </Typography>
          </Stack>
          <KotaKabupatenForm
            data={data}
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </Container>
        )}
    </>
  );
};
  
export default EditKotaKabupatenPage;
