import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack } from '@mui/material';
import { KecamatanForm } from '../../../sections/@dashboard/kecamatan';

const AddKecamatanPage = ({handleCloseModal, handleUpdateTable}) => (
    <>
      <Helmet>
        <title> Kecamatan | Sip App </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tambah Kecamatan
          </Typography>
        </Stack>
        <KecamatanForm
          handleCloseModal={handleCloseModal}
          handleUpdateTable={handleUpdateTable}
        />
      </Container>
    </>
  );
  
export default AddKecamatanPage;
