import React, { useEffect } from 'react';
import {
  Box,
  IconButton
} from '@mui/material';
import { useField } from 'formik';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import CancelIcon from '@mui/icons-material/Cancel';
import { red, blueGrey } from '@mui/material/colors';

import './UploadField.css';

export default function UploadTtdField(props) {
  const { id, label, urlValue, handleOpenModal, ...rest } = props;
  const [field, meta, helper] = useField(rest);
  const { setValue } = helper;
  const { value } = field;

  const _onUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader(file);

    reader.onloadend = () => {
      setValue(reader.result);
    };

    reader.readAsDataURL(file);
  }

  const onDelete = () => {
    setValue('');
  }

  useEffect(() => {
    if(urlValue) {
      setValue(urlValue);
    }
  }, [urlValue, setValue]);

  return (
    <>
      <label htmlFor={id}>
        {label}
        <input
          {...rest}
          hidden
          id={id}
          accept="image/*"
          type="file"
          onChange={_onUpload}
        />
        <Box
          className="upload-field-container"
        >
          {value ? (
            <Box>
              <img
                src={value}
                alt="Upload Foto"
                className="uploaded-image"
              />
              <IconButton
                aria-label="remove picture"
                component="span"
                sx={{
                  color: red['500'],
                  position: 'absolute',
                  top: 0,
                  right: 0
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onDelete();
                }}
              >
                <CancelIcon />
              </IconButton>
            </Box>
          ):(
            <Box>
              <IconButton
                aria-label="upload picture"
                component="span"
                sx={{
                  color: blueGrey['500']
                }}>
                <PhotoCamera />
              </IconButton>
              <IconButton
                sx={{
                  color: blueGrey['500']
                }}
                aria-label="custom action"
                onClick={handleOpenModal}
              >
                <AssistantDirectionIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </label>
      {meta.touched && meta.error ? (
        <div style={{ color: "red" }}>{meta.error}</div>
      ) : null}
    </>
  );
}
