import { useEffect, useState } from 'react';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { SaldoCutiForm } from '../../../sections/@dashboard/cuti/saldo';
import { axiosPrivate } from '../../../common/axiosPrivate';

const EditSaldoCutiPage = ({id, handleCloseModal, handleUpdateTable}) => {
  const [data, setData] = useState({});
  const [dropDown, setDropDown] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    const getDropdown = async () => {
      await axiosPrivate.get('saldo-cuti/dropdown/list')
      .then((response) => {
        const data = response?.data;
        
        setDropDown(data);
      })
      .catch((error) => console.log(error));
    }

    getDropdown()
    .finally(() => {
      axiosPrivate.get(`/saldo-cuti/${id}`)
      .then((response) => setData(response?.data))
      .catch((error) => console.log(error?.response))
      .finally(() => setPageLoading(false));
    });
  }, [id]);

  return (
    <>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Ubah Saldo Cuti
            </Typography>
          </Stack>
          <SaldoCutiForm
            data={data}
            dropDown={dropDown}
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </Container>
      )}
    </>
  );
}
  
export default EditSaldoCutiPage;
