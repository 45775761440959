import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo, useContext } from 'react';
// @mui
import {
  Card,
  Container,
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import { getCurrentDate } from "../../../utils/formatDate";
import Label from '../../../components/label';
// API
import { axiosPrivate } from '../../../common/axiosPrivate';
// Context
import AuthContext from '../../../context/authContext';

export default function LapAbsenPage() {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
     name: "nama",
     label: "Nama",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "kodePegawai",
     label: "Kode",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "kantor",
      label: "Kantor",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "jabatan",
      label: "Jabatan",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "statusKehadiran",
      label: "Status Kehadiran",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value !== "Tidak Absen" ? (
          <Label color='error'>{value}</Label>
        ) : (
          <Label color='warning'>{value}</Label>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `laporan-absen-pegawai-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  // Fetch Api
  useEffect(() => {
    axiosPrivate.post(`laporan/absen-pegawai/${user?.kantor}`, {
      date: null,
    })
    .then((response) => setData(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, [user?.kantor]);

  return (
    <>
      <Helmet>
        <title> Laporan Kehadiran Absen | Sip App </title>
      </Helmet>

      <Container>
        <Card>
          <MUIDataTable
            title="Laporan Kehadiran Absen"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>
    </>
  );
}
