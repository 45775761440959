import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
// @mui
import {
  Card,
  Container,
  Stack,
  Modal,
  Box,
  Avatar,
  Typography,
  IconButton,
  Button
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import AddUserPage from './AddUserPage';
import EditUserPage from './EditUserPage';
import { TableLoader } from '../../components/table';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { getCurrentDate } from "../../utils/formatDate";
import { axiosPrivate } from '../../common/axiosPrivate';

export default function UserPage() {
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [tableLoading, setTableLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
      name: "nama",
      label: "Nama",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (nama, meta) => {
          const avatar = meta.rowData[8];
          const avatarUrl = avatar ? `/assets/images/avatars/${avatar}.jpg` : '';

          return (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar alt={avatarUrl} src={avatarUrl} imgProps={{ referrerPolicy: "no-referrer" }} />
              <Typography variant="subtitle2" noWrap>
                {nama}
              </Typography>
            </Stack>
          )
        }
      }
    },
    {
     name: "email",
     label: "Email",
     options: {
        filter: false,
        sort: false
     }
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "kantor",
      label: "Kode Kantor",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "namaKantor",
      label: "Kantor",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, meta) => meta.rowData[5] === '9999' ? 'All' : value
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data) => <Label color={data ? 'success' : 'error'}>{data ? 'Aktif' : 'TIdak Aktif'}</Label>
      }
    },
    {
      name: "avatar",
      label: "Avatar",
      options: {
        filter: false,
        sort: true,
        display:false
      }
    },
    {
      name: "_id",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customHeadRender: () => null,
        customBodyRender: (value) => (
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleOpenModal(1, value)}
          >
            <Iconify icon={'eva:edit-fill'} />
            Edit
          </Button>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `User-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    }
  };

  const handleOpenModal = (content, id) => {
    setSelectedId(id);
    setModalContent(content);
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleUpdateTable = (newData) => {
    const index = data.findIndex(item => item._id === newData._id);
  
    if (index !== -1) {
      data[index] = { ...data[index], ...newData };
      setData([...data]);
    } else {
      setData([newData, ...data]);
    }
  };

  // Fetch Api
  useEffect(() => {
    axiosPrivate.get('User')
    .then((response) => setData(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, []);

  return (
    <>
      <Helmet>
        <title> List User | Sip App </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 2 }}>
          <Button
            onClick={() => handleOpenModal(0)}
            variant='contained'
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Tambah
          </Button>
        </Stack>
        <Card>
          <MUIDataTable
            title="List Users"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        hideBackdrop
        open={modalOpen}
        onClose={handleCloseModal}
        style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {modalContent === 0 ? (
            <AddUserPage
              handleCloseModal={handleCloseModal}
              handleUpdateTable={handleUpdateTable}
            />
          ) : (
            <EditUserPage
              id={selectedId}
              handleCloseModal={handleCloseModal}
              handleUpdateTable={handleUpdateTable}
            />
          )}
        </>
      </Modal>
    </>
  );
}
