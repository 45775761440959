import * as Yup from 'yup';
import formModel from './formModel';

const {
  formField: {
    dokumen,
    pegawai,
    nama,
    penerima,
    tglTerima
  }
} = formModel;

export default Yup.object({
  [dokumen.name]: Yup.string().required(`${dokumen.requiredErrorMsg}`),
  [pegawai.name]: Yup.string().required(`${pegawai.requiredErrorMsg}`),
  [nama.name]: Yup.string().required(`${nama.requiredErrorMsg}`),
  [penerima.name]: Yup.string().required(`${penerima.requiredErrorMsg}`),
  [tglTerima.name]: Yup.string().required(`${tglTerima.requiredErrorMsg}`)
});
