import React, { useContext } from 'react';
import moment from 'moment';
import {
  Stack,
  Grid,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { InputField, SelectField, DatePickerField, AutocompleteField } from '../../../components/form-fields';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';
import AuthContext from '../../../context/authContext';

import validationSchema from './form-model/validationSchema';
import promosiFormModel from './form-model/promosiFormModel';
import formInitialValues from './form-model/formInitialValues';

export default function PromosiForm({data, dropDown, handleCloseModal, handleUpdateTable}) {
  const { user } = useContext(AuthContext);
  const notificationCtx = useContext(NotificationContext);
  const pegawai = (dropDown || []).filter(x => x.kategori === 'pegawai');
  const jabatan = (dropDown || []).filter(x => x.kategori === 'jabatan');
  const { formId, formField } = promosiFormModel;
  const { kodePegawai, dariJabatan, keJabatan, tglMulai, tglAkhir, keterangan } = formField;

  const _handleInitialValues = data !== undefined ?  {
    kodePegawai: data.kodePegawai,
    dariJabatan: data.dariJabatan,
    keJabatan: data.keJabatan,
    tglMulai: data.tglMulai,
    tglAkhir: data.tglAkhir,
    keterangan: data.keterangan ? data.keterangan : ''
  } : formInitialValues

  const handleMessage = async (params) => {
    const success = params?.success;

    if (success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: params?.message
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.message
      });
    }
  };

  const _handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const params = {
      kodePegawai: values?.kodePegawai,
      dariJabatan: values?.dariJabatan,
      keJabatan: values?.keJabatan,
      tglMulai: values?.tglMulai,
      tglAkhir: values?.tglAkhir,
      keterangan: values?.keterangan,
      dibuatOleh: user?._id
    };

    if (data?._id) {
      params._id = data?._id;
      params.tglUbah = moment().format('YYYY-MM-DDTHH:mm:ss');
    } else {
      params.tglBuat = moment().format('YYYY-MM-DDTHH:mm:ss');
    }

    // console.log(JSON.stringify(params));

    const endpoint = data?._id
      ? axiosPrivate.put(`/promosi/${data._id}`, params)
      : axiosPrivate.post(`/promosi`, params);

    try {
      const response = await endpoint;
      const { result } = response?.data;

      handleUpdateTable(result);
      handleMessage(response?.data);

      if (!data?._id) {
        resetForm();
      }
    } catch (error) {
      handleMessage(error);
    } finally {
      setSubmitting(false);
    }
  };

  const _onSetValue = (value, setFieldValue) => {
    if(value) {
      axiosPrivate.get(`/promosi/jabatan/${value}`)
      .then((response) => {
        const jabatanValue = response?.data;
        setFieldValue('dariJabatan', jabatanValue);
      })
      .catch((error) => console.log(error))
    }
  }

  return (
    <Formik
      initialValues={_handleInitialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <AutocompleteField
                name={kodePegawai.name}
                label={kodePegawai.label}
                data={pegawai}
                onSetValue={(value) =>_onSetValue(value, setFieldValue)}
                readOnly={!!data?.kodePegawai}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SelectField
                name={dariJabatan.name}
                label={dariJabatan.label}
                data={jabatan}
                readOnly
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SelectField
                name={keJabatan.name}
                label={keJabatan.label}
                data={jabatan}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField name={keterangan.name} label={keterangan.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DatePickerField
                name={tglMulai.name}
                label={tglMulai.label}
                format="dd/MM/yyyy"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DatePickerField
                name={tglAkhir.name}
                label={tglAkhir.label}
                format="dd/MM/yyyy"
                fullWidth
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseModal}
            >
              Kembali
            </Button>
            <LoadingButton type="submit" loading={isSubmitting} variant="contained">
              Simpan
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
