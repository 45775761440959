import { useState } from 'react';
import {
  Box,
  IconButton,
  Typography
} from '@mui/material';
import { useField } from 'formik';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CancelIcon from '@mui/icons-material/Cancel';
import { red, blueGrey } from '@mui/material/colors';
import Iconify from "../iconify";

export default function UploadField(props) {
  const { id, label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const { value } = field;
  const [fileLabel, setFileLabel] = useState('');

  const _onUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader(file);

    reader.onloadend = () => {
      setFileLabel(e.target.files[0].name);
      setValue(reader.result);
    };

    if(file) {
      reader.readAsDataURL(file);
    }
  }

  const _onDelete = (e) => {
    e.preventDefault();
    setValue('');
  }

  return (
    <>
      <label htmlFor={id}>
        {label}
        <input
          {...rest}
          hidden
          id={id}
          accept="application/pdf"
          type="file"
          onChange={_onUpload}
        />
        <Box
          display="flex" 
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'relative',
            width: 300,
            height: 300,
            overflow: 'hidden',
            backgroundColor: blueGrey['100'],
          }}
        >
          {value ? (
            <Box>
              <Typography
                variant="body2"
                component="p"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:"center",
                  flexDirection: "column",
                  padding: "10px"
                }}
              >
                <Iconify icon={'vscode-icons:file-type-pdf2'} width="80px" height="80px" />
                {fileLabel}
              </Typography>
              <IconButton
                aria-label="remove file"
                component="span"
                sx={{
                  color: red['500'],
                  position: 'absolute',
                  top: 0,
                  right: 0
                }}
                onClick={(e) => _onDelete(e, 'profilUrl')} >
                <CancelIcon />
              </IconButton>
            </Box>
          ):(
            <IconButton
              aria-label="upload file"
              component="span"
              sx={{
                color: blueGrey['500']
              }} >
              <UploadFileIcon />
            </IconButton>
          )}
        </Box>
      </label>
      {meta.touched && meta.error ? (
        <div style={{ color: "red" }}>{meta.error}</div>
      ) : null}
    </>
  );
}
