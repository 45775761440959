export default {
  formId: 'absenForm',
  formField: {
    tanggal: {
      name: 'tanggal',
      label: 'Tanggal*',
      requiredErrorMsg: 'Tanggal harus dipilih'
    },
    jamMasuk: {
      name: 'jamMasuk',
      label: 'Jam Masuk'
    },
    jamKeluar: {
      name: 'jamKeluar',
      label: 'Jam Keluar'
    },
    kodePegawai: {
      name: 'kodePegawai',
      label: 'Pegawai*',
      requiredErrorMsg: 'Kode Pegawai harus dipilih'
    },
    jenis: {
      name: 'jenis',
      label: 'Absensi*',
      requiredErrorMsg: 'Absensi harus dipilih'
    },
    hari: {
      name: 'hari',
      label: 'Jumlah Hari'
    },
    image: {
      name: 'image',
      label: 'Bukti Gambar'
    },
    keterangan: {
      name: 'keterangan',
      label: 'Keterangan*',
      requiredErrorMsg: 'Keterangan harus dipilih'
    }
  }
};
