import * as Yup from 'yup';
// import { addDays, format } from 'date-fns';
import reqCutiFormModel from './reqCutiFormModel';

const {
  formField: {
    kodePegawai,
    dariTanggal,
    hari,
    attachment,
    keterangan
  }
} = reqCutiFormModel;

// const minDate = addDays(new Date(), 13);
// const formattedMinDate = format(addDays(new Date(minDate), 1), 'dd/MM/yyyy');

export default Yup.object({
  [kodePegawai.name]: Yup.string().required(`${kodePegawai.requiredErrorMsg}`),
  [dariTanggal.name]: Yup.date()
                        .min(new Date(), `Tanggal harus minimal ${new Date()}`)
                        .required(`${reqCutiFormModel.formField.dariTanggal.requiredErrorMsg}`),
  [hari.name]: Yup.number()
                .typeError('Hari harus number')
                .required(`${hari.requiredErrorMsg}`),
  [attachment.name]: Yup.string().required(`${attachment.requiredErrorMsg}`),
  [keterangan.name]: Yup.string().required(`${keterangan.requiredErrorMsg}`)
});
