import * as Yup from 'yup';
import pegawaiFormModel from './pegawaiFormModel';

const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i+=1)
    ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString });
}

const {
  formField: {
    kode,
    nama,
    jenisKelamin,
    tempatLahir,
    tglLahir,
    statusPerkawinan,
    agama,
    pendidikan,
    kotaKab,
    kecamatan,
    alamat,
    useDomisili,
    nik,
    email,
    tglMasuk,
    kantor,
    jabatan,
    statusPegawai,
    profilUrl,
    ttdUrl
  }
} = pegawaiFormModel;

export default [
  Yup.object().shape({
    [kode.name]: Yup.string().required(`${kode.requiredErrorMsg}`),
    [nama.name]: Yup.string().required(`${nama.requiredErrorMsg}`),
    [jenisKelamin.name]: Yup.string().required(`${jenisKelamin.requiredErrorMsg}`),
    [tempatLahir.name]: Yup.string().required(`${tempatLahir.requiredErrorMsg}`),
    [tglLahir.name]: Yup.string().required(`${tglLahir.requiredErrorMsg}`),
    [statusPerkawinan.name]: Yup.string().required(`${statusPerkawinan.requiredErrorMsg}`),
    [agama.name]: Yup.string().required(`${agama.requiredErrorMsg}`),
    [pendidikan.name]: Yup.string().required(`${pendidikan.requiredErrorMsg}`),
    [kotaKab.name]: Yup.string().required(`${kotaKab.requiredErrorMsg}`),
    [kecamatan.name]: Yup.string().required(`${kecamatan.requiredErrorMsg}`),
    [alamat.name]: Yup.string().required(`${alamat.requiredErrorMsg}`),
    [useDomisili.name]: Yup.string().required(`${useDomisili.requiredErrorMsg}`)
  }),
  Yup.object().shape({
    [nik.name]: Yup.string().required(`${nik.requiredErrorMsg}`),
    [email.name]: Yup.string().email(`${email.emailErrorMsg}`).required(`${email.requiredErrorMsg}`)
  }),
  Yup.object().shape({
    [tglMasuk.name]: Yup.string().required(`${tglMasuk.requiredErrorMsg}`),
    [kantor.name]: Yup.string().required(`${kantor.requiredErrorMsg}`),
    [jabatan.name]: Yup.string().required(`${jabatan.requiredErrorMsg}`),
    [statusPegawai.name]: Yup.string().required(`${statusPegawai.requiredErrorMsg}`)
  }),
  Yup.object().shape({
    [profilUrl.name]: Yup.mixed()
    // .required(`${profilUrl.requiredErrorMsg}`)
    .test("fileSize", `${profilUrl.toBig}`, (file) => 
      {
        if(file === undefined ) {
          return true;
        }
        
        const blob = DataURIToBlob(file);
        const {size} = blob;
        
        return size <= 2000000;
      }
    ),
    [ttdUrl.name]: Yup.mixed()
    .test("fileSize", `${ttdUrl.toBig}`, (file) => 
      {
        if(file === undefined ) {
          return true;
        }

        const blob = DataURIToBlob(file);
        const {size} = blob;
      
        return size <= 2000000;
      }
    )
  })
];
