import { useState } from 'react';
import {
  Grid,
  Box, 
  Modal,
  IconButton
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import PageSignaturePegawai from '../../../../pages/kepegawaian/pegawai/PageSignaturePegawai';
import { UploadField, UploadTtdField } from '../../../../components/form-fields';

export default function FotoForm(props) {
  const {
    formField: {
      profilUrl,
      ttdUrl
    }
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState('');

  const handleOpenModal = () => {
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleSetValue = (value) => {
    setValue(value);
  }

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6}>
          <UploadField
            id={profilUrl.id}
            name={profilUrl.name}
            label={profilUrl.label}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UploadTtdField
            id={ttdUrl.id}
            name={ttdUrl.name}
            label={ttdUrl.label}
            urlValue={value}
            handleOpenModal={handleOpenModal}
          />
          <Modal
            sx={{
              overflowY: 'scroll',
              pt: 5,
              pb: 5
            }}
            hideBackdrop
            open={modalOpen}
            onClose={handleCloseModal}
            style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
          >
            <>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  margin: 1
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseModal}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <PageSignaturePegawai
                handleSetValue={handleSetValue}
                handleCloseModal={handleCloseModal}
              />
            </>
          </Modal>
        </Grid>
      </Grid>
    </>
  );
}
