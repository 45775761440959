import React, { useState } from 'react';

const NotificationContext = React.createContext({
  open: false,
  severity: 'success',
  title: 'Success!',
  message: 'This is a success message.',
  setup: () => {},
});

const NotificationProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [title, setTitle] = useState('Success!');
  const [message, setMessage] = useState('This is a success message.');

  const setup = (item) => {
    setOpen(true);
    setSeverity(item.severity);
    setTitle(item.title);
    setMessage(item.message);
  };

  const clear = () => {
    setOpen(false);
    // setSeverity('');
    // setTitle('');
    // setMessage('');
  };

  return (
    <NotificationContext.Provider
      value={{
        open,
        severity,
        title,
        message,
        setup,
        clear,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider };
export default NotificationContext;
