import processCutiFormModel from './processCutiFormModel';

const {
  formField: {
    dariTanggal,
    hari,
    keterangan
  }
} = processCutiFormModel;

export default {
  [dariTanggal.name]: '',
  [hari.name]: '',
  [keterangan.name]: ''
};
