import reqCutiFormModel from './reqCutiFormModel';

const {
  formField: {
    kodePegawai,
    dariTanggal,
    saldo,
    hari,
    khusus,
    attachment,
    keterangan
  }
} = reqCutiFormModel;

// const twoWeeksFromNow = new Date();
// twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);

// const formattedDate = twoWeeksFromNow.toISOString().split('T')[0];

export default {
  [kodePegawai.name]: '',
  // [dariTanggal.name]: formattedDate,
  [dariTanggal.name]: new Date(),
  [saldo.name]: '',
  [hari.name]: '',
  [khusus.name]: 'tidak',
  [attachment.name]: '',
  [keterangan.name]: ''
};
