import * as Yup from 'yup';
import kantorFormModel from './kantorFormModel';

const {
  formField: {
    nama,
    kode
  }
} = kantorFormModel;

export default Yup.object({
  [kode.name]: Yup.string().required(`${kode.requiredErrorMsg}`),
  [nama.name]: Yup.string().required(`${nama.requiredErrorMsg}`)
});
