import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
// @mui
import {
  Card,
  Container,
  IconButton,
  Button,
  Modal,
  Box
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { format, parseISO } from 'date-fns';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import Iconify from '../../../components/iconify';
import ViewAbsenRumpunPage from './ViewAbsenRumpunPage';
import { getCurrentDate } from "../../../utils/formatDate";
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function MutasiPage() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
     name: "tanggal",
     label: "Tanggal",
     options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : '',
     }
    },
    {
      name: "kodePegawai",
      label: "Kode",
      options: {
       filter: true,
       sort: false,
      }
    },
    {
      name: "petugas",
      label: "Petugas",
      options: {
       filter: true,
       sort: false,
      }
    },
    {
      name: "jabatan",
      label: "Jabatan",
      options: {
       filter: false,
       sort: false,
      }
     },
    {
      name: "kantor",
      label: "Kantor",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: 'absen',
      label: 'Total',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => value.length
      }
    },
    {
    name: "kodePegawai",
    label: "Aksi",
    options: {
      filter: false,
      sort: false,
      customHeadRender: () => null,
      customBodyRender: (value, meta) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleOpenModal(meta.rowData)}
        >
          <Iconify icon={'eva:eye-fill'} sx={{ marginRight: '4px' }} />
          Lihat
        </Button>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `absensi-rumpun-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  const handleOpenModal = (rowData) => {
    setSelectedRow(rowData);
    // setModalContent(content);
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleUpdateTable = (newData) => {
    const index = data.findIndex(item => item._id === newData._id);
  
    if (index !== -1) {
      data[index] = { ...data[index], ...newData };
      setData([...data]);
    } else {
      setData([newData, ...data]);
    }
  };

  // Fetch Api
  useEffect(() => {
    axiosPrivate.get('pegawai-absen-rumpun')
    .then((response) => {
      // console.log(response);
      setData(response?.data);
    })
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, []);

  return (
    <>
      <Helmet>
        <title> List Absen Rumpun | Sip App </title>
      </Helmet>

      <Container sx={{ marginTop: 5 }}>
        <Card>
          <MUIDataTable
            title="List Absensi Rumpun"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        hideBackdrop
        open={modalOpen}
        onClose={handleCloseModal}
        style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <ViewAbsenRumpunPage
            absen={selectedRow}
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </>
      </Modal>
    </>
  );
}
