import { useEffect, useState, useContext } from 'react';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { CabangAbsenForm } from '../../../sections/cabang/absen';
import { axiosPrivate } from '../../../common/axiosPrivate';
import AuthContext from '../../../context/authContext';

const CabangEditAbsenPage = ({id, handleCloseModal, handleUpdateTable}) => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState({});
  const [dropDown, setDropDown] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    const fetchData = async () => {
      try {
        // Fetch dropdown data
        const dropdownResponse = await axiosPrivate.get(`pegawai-absen/dropdown/list/${user?.kantor}`);
        setDropDown(dropdownResponse.data);

        // Fetch specific absen data
        if (id) {
          const absenResponse = await axiosPrivate.get(`/pegawai-absen/${id}`);
          const absenData = absenResponse.data;
          const objectKey = absenData.image;

          // Adjust the image URL if necessary
          if (objectKey && objectKey.startsWith('/sip-photos')) {
            const formattedKey = objectKey.startsWith('/') ? objectKey.substring(1) : objectKey;
            const response = await axiosPrivate.post(`/files/signed-url`, { key: formattedKey });
            
            absenData.image = response?.data?.signedUrl;
          }
          setData(absenData);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setPageLoading(false);
      }
    };

    fetchData();
  }, [id, user?.kantor]);
  
  return (
    <>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Ubah Absen
            </Typography>
          </Stack>
          <CabangAbsenForm
            data={data}
            dropDown={dropDown}
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </Container>
      )}
    </>
  )
}
  
export default CabangEditAbsenPage;
