import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Card } from '@mui/material';
import { ProcessCutiForm } from '../../../sections/@dashboard/cuti/process';

const ProcessCutiPage = ({handleCloseModal, handleUpdateTable}) => (
    <>
      <Helmet>
        <title> Proses Cuti | Sip App </title>
      </Helmet>

      <Container>
        <Card sx={{p: 2}}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h6" gutterBottom>
              Proses Cuti
            </Typography>
          </Stack>
          <ProcessCutiForm
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </Card>
      </Container>
    </>
  )
  
export default ProcessCutiPage;
