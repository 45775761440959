import React, { useState, useEffect } from 'react';
import { CardMedia, CircularProgress, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { axiosPrivate } from '../../common/axiosPrivate';

const ImageComponent = ({ objectKey, onClose }) => {
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Define the function to fetch the image URL
        const fetchImageUrl = async () => {
            try {
                setLoading(true); // Start loading
                const formattedKey = objectKey.startsWith('/') ? objectKey.substring(1) : objectKey;
                const response = await axiosPrivate.post(`/files/signed-url`, { key: formattedKey });
                const url = response?.data?.signedUrl;
                setImageUrl(url);
            } catch (error) {
                console.log('Error fetching image URL:', error);
            } finally {
                setTimeout(() => {
                    setLoading(false); // Stop loading regardless of the outcome after a delay
                }, 1000);
            }
        };

        if (objectKey) {
            fetchImageUrl();
        }
    }, [objectKey]); // Re-run the effect if objectKey changes

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative' // Added to position the close button absolutely within the Box
            }}
        >
            {loading && (
                <CircularProgress size={48} />
            )}
            {imageUrl && (
                <CardMedia
                    component="img"
                    src={imageUrl}
                    alt="Loaded image"
                    title="Loaded image from S3"
                    sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Optional: adjust the fit as needed
                        display: loading ? 'none' : 'block' // Hide image while loading
                    }}
                />
            )}
            {imageUrl && !loading && (
                <IconButton
                    onClick={onClose} // Assumes an `onClose` prop function is passed to handle the close action
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: 'white', // Set color to white or another suitable color depending on background
                        background: 'rgba(0, 0, 0, 0.7)', // Optional: background to make the button visible on varied images
                        '&:hover': {
                            background: 'rgba(0, 0, 0, 0.9)', // Darken on hover
                        }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </Box>
    );
}

export default ImageComponent;
