export default {
  formId: 'reqCutiForm',
  formField: {
    kodePegawai: {
      name: 'kodePegawai',
      label: 'Pegawai*',
      requiredErrorMsg: 'Kode Pegawai harus dipilih'
    },
    dariTanggal: {
      name: 'dariTanggal',
      label: 'Dari Tanggal*',
      requiredErrorMsg: 'Dari Tanggal harus dipilih'
    },
    saldo: {
      name: 'saldo',
      label: 'Sisa Saldo'
    },
    hari: {
      name: 'hari',
      label: 'Pengajuan*',
      requiredErrorMsg: 'Pengajuan harus dipilih'
    },
    khusus: {
      name: 'khusus',
      label: 'Cuti Khusus',
    },
    attachment: {
      name: 'attachment',
      label: 'Lampiran*',
      requiredErrorMsg: 'Lampiran harus diisi'
    },
    keterangan: {
      name: 'keterangan',
      label: 'Keterangan*',
      requiredErrorMsg: 'Ketrangan harus diisi'
    }
  }
};
