import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Stack,
  Typography,
  FormControl,
  Input,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import XLSX from 'xlsx';
import NotificationContext from '../../../context/notificationContext';
import { axiosPrivate } from '../../../common/axiosPrivate';

function ImportSaldoCutiPage() {
  const notificationCtx = useContext(NotificationContext);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(selectedFile);
      fileReader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        
        // Configure date parsing options to handle potential date values
        const excelData = XLSX.utils.sheet_to_json(sheet, { 
          header: 1,
          raw: false, // Keep dates as Excel stores them
          dateNF: 'dd/mm/yyyy', // Specify the expected date format
        });

        // Convert the array of arrays (excelData) into an array of objects
        const headers = excelData[0];
        const rows = excelData.slice(1);
        const staticKeys = [
          'kodePegawai',
          'namaPegawai',
          'tahun',
          'saldo'
        ];
        const objectData = rows.map((row) =>
          headers.reduce((obj, _, index) => {
            if (index !== 1) { // Skip the "Nama Pegawai" column
              const sanitizedHeader = headers[index].replace(/\s+/g, ''); // Remove spaces from header key
              const staticKey = staticKeys[index] || sanitizedHeader; // Use static key if available
              obj[staticKey] = row[index];
            }

            return obj;
          }, {})
        );

        // console.log(JSON.stringify(objectData));

        // Save the data to the server API
        await axiosPrivate.post(`/saldo-cuti/import`, objectData)
        .then((response) => {
          _handleMessage(response);
        })
        .catch((error) => {
          console.log(error);
          _handleMessage(error)
        })
        .finally(() => {
          setLoading(false);
        });
      };
    } catch (error) {
      console.error('Error reading the Excel file:', error);
      setLoading(false);
    }
  };

  const _handleMessage = (params) => {
    if(params?.status === 200) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: 'Data Berhasil Diimport.'
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.response.data
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Import Saldo Cuti | Sip App</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Import Saldo Cuti
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <Input type="file" onChange={handleFileChange} />
          </FormControl>
          <Stack
            direction="row"
            justifyContent="end"
            mt={2}
            spacing={1}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(-1)}
            >
              Kembali
            </Button>
            <LoadingButton
              type="submit"
              loading={loading}
              variant="contained"
            >
              Simpan
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </>
  );
}

export default ImportSaldoCutiPage;
