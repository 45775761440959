import React, { useContext } from 'react';
import {
  Stack,
  Grid,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { InputField, AutocompleteField } from '../../../../components/form-fields';

import { axiosPrivate } from '../../../../common/axiosPrivate';
import NotificationContext from '../../../../context/notificationContext';

import validationSchema from './form-model/validationSchema';
import saldoCutiFormModel from './form-model/saldoCutiFormModel';
import formInitialValues from './form-model/formInitialValues';

export default function SaldoCutiForm({data, dropDown, handleCloseModal, handleUpdateTable}) {
  const notificationCtx = useContext(NotificationContext);
  const pegawai = (dropDown || []).filter(x => x.kategori === 'pegawai');
  const { formId, formField } = saldoCutiFormModel;
  const { kodePegawai, tahun, saldo } = formField;

  const _handleInitialValues = data !== undefined ?  {
    kodePegawai: data.kodePegawai,
    tahun: data.tahun,
    saldo: data.saldo
  } : formInitialValues

  const handleMessage = async (params) => {
    const success = params?.success;

    if (success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: params?.message
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.message
      });
    }
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const params = {
      kodePegawai: values?.kodePegawai,
      tahun: values?.tahun,
      saldo: values?.saldo
    };

    const endpoint = data?._id
      ? axiosPrivate.put(`/saldo-cuti/${data._id}`, params)
      : axiosPrivate.post(`/saldo-cuti`, params);

    try {
      const response = await endpoint;
      const { result } = response?.data;

      handleUpdateTable(result);
      handleMessage(response?.data);

      if (!data?._id) {
        resetForm();
      }
    } catch (error) {
      handleMessage(error?.response?.data);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <Formik
      initialValues={_handleInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <AutocompleteField
                name={kodePegawai.name}
                label={kodePegawai.label}
                data={pegawai}
                readOnly={!!data?.kodePegawai}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name={tahun.name}
                label={tahun.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name={saldo.name}
                label={saldo.label}
                fullWidth
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseModal}
            >
              Kembali
            </Button>
            <LoadingButton type="submit" loading={isSubmitting} variant="contained">
              Simpan
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
