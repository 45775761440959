import { useEffect, useState, useContext } from 'react';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { ReqCutiForm } from '../../../sections/cabang/cuti';
import { axiosPrivate } from '../../../common/axiosPrivate';
import AuthContext from '../../../context/authContext';

const AddReqCutiPage = ({handleCloseModal, handleUpdateTable}) => {
  const { user } = useContext(AuthContext);
  const [dropDown, setDropDown] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    axiosPrivate.get(`req-cuti/dropdown/list/${user?.kantor}`)
    .then((response) => setDropDown(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setPageLoading(false));
  }, [user?.kantor]);

  return (
    <>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Request Cuti
            </Typography>
          </Stack>
          <ReqCutiForm
            dropDown={dropDown}
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </Container>
      )}
    </>
  );
}
  
export default AddReqCutiPage;
