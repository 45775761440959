// eslint-disable-next-line import/no-unresolved
import mem from "mem";
import Cookies from 'js-cookie';
import { axiosPublic } from "./axiosPublic";

const refreshTokenFn = async () => {
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = JSON.parse(localStorage.getItem('userData'));
    const response = await axiosPublic.post("refresh-token", {
      refresh_token : user?.refresh_token,
    });

    const { data } = response;

    if (!data?.access_token) {
      Cookies.remove("api_token");
      localStorage.removeItem("userData");
    }

    Cookies.set("api_token", data?.access_token);

    return data;
  } catch (error) {
    localStorage.removeItem("userData");
  }
  
  return null
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
