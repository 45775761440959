import kantorFormModel from './kantorFormModel';

const {
  formField: {
    kode,
    nama
  }
} = kantorFormModel;

export default {
  [kode.name]: '',
  [nama.name]: ''
};
