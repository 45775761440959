import pegawaiKeluarFormModel from './pegawaiKeluarFormModel';

const {
  formField: {
    kodePegawai,
    tglKeluar,
    sebab,
    keterangan
  }
} = pegawaiKeluarFormModel;

export default {
  [kodePegawai.name]: '',
  [tglKeluar.name]: '',
  [sebab.name]: '',
  [keterangan.name]: ''
};
