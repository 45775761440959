import { useEffect, useState, useMemo } from 'react';
import axios from "axios";
// @mui
import {
  Card,
  Container,
  IconButton,
  Box, 
  Modal,
  TableCell,
  TableFooter,
  TableRow
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NumericFormat } from 'react-number-format';
import MUIDataTable from "mui-datatables";
import CloseIcon from "@mui/icons-material/Close";
import { TableLoader } from '../../../components/table';
import { getCurrentDate } from "../../../utils/formatDate";
// Context
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function KinerjaPegawaiPage({idPegawai}) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pegawai, setPegawai] = useState({});
  const [tableLoading, setTableLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [tableTitle, setTableTitle] = useState('');
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const useStyles = makeStyles(() => ({
    footerCell: {
      backgroundColor: "#ebeef2",
      fontSize: 14,
      fontWeight: "bolder"
    }
  }));

  const classes = useStyles();

  const columns = [
    {
      name: "NUMBER",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
      name: "KANTOR",
      label: "kantor",
      options: {
       filter: true,
       sort: true,
      }
     },
    {
     name: "TAHUN",
     label: "Tahun",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "BULAN",
      label: "Bulan",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "JMLDROPING",
      label: "Pembiayaan Aktif",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "DROPING",
      label: "Droping",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "PENDAPATAN",
      label: "Pendapatan",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "SUKARELA",
      label: "Sukarela",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "BERENCANA",
      label: "Berencana",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "SIBERANI",
      label: "Siberani",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "INFAQ",
      label: "Infaq",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `kinerja-${pegawai?.nama}-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setRowsPerPage(numberOfRows);
    },
    onChangePage(page) {
      setPage(page);
    },
    customTableBodyFooterRender: (opts) => {
      const startIndex = page * rowsPerPage;
      const endIndex = (page + 1) * rowsPerPage;
      // console.log(opts.data);
      const sumJmlDroping = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[4].props.children.props.children.props.value), 0);
      const sumDroping = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[5].props.children.props.children.props.value), 0);
      const sumPendapatan = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[6].props.children.props.children.props.value), 0);
      const sumSukarela = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[7].props.children.props.children.props.value), 0);
      const sumBerencana = opts.data
        ?.slice(startIndex, endIndex)
        ?.reduce((value, item) => value + parseFloat(item.data[8].props.children.props.children.props.value), 0);
      const sumSiberani = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[9].props.children.props.children.props.value), 0);
      const sumInfaq = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[10].props.children.props.children.props.value), 0);
      
      return (
        <>
          {data.length > 0 && (
            <TableFooter className={classes.footerCell}>
              <TableRow>
                {opts.columns.map((col, index) => {
                  // console.log(col.name);
                  if (col.display === "true") {
                    if (col.name === "NUMBER") {
                      return (
                        <TableCell key={index} className={classes.footerCell} colSpan={4} align='center'>
                          Total
                        </TableCell>
                      );
                    } if (col.name === "JMLDROPING") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumJmlDroping) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumJmlDroping).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "DROPING") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumDroping) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumDroping).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "PENDAPATAN") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumPendapatan) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumPendapatan).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "SUKARELA") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumSukarela) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumSukarela).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "BERENCANA") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumBerencana) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumBerencana).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "SIBERANI") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumSiberani) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumSiberani).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "INFAQ") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumInfaq) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumInfaq).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    }
                  }

                  return null;
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  // Function to convert month number to month name
  function getMonthName(monthNumber) {
    const months = [
        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    return months[monthNumber - 1] || "";
  }

  // Function to convert string numbers to integers
  function convertToInteger(value) {
    if (value === undefined) {
      return 0;
    }

    const floatValue = parseFloat(value);
    return Number.isNaN(floatValue) ? value : Math.round(floatValue);
  }

  // const fetchBatchResults = useCallback(async (batchId) => {
  //   const mergeResults = (results) => {
  //     const merged = {};
    
  //     results.forEach(item => {
  //       const key = `${item.NOKANT}_${item.TAHUN}_${item.BULAN}`;
  //       if (!merged[key]) {
  //         merged[key] = {
  //           KANTOR: item.KANTOR,
  //           TAHUN: item.TAHUN,
  //           BULAN: getMonthName(item.BULAN),
  //           SUKARELA: convertToInteger(item.SUKARELA),
  //           BERENCANA: convertToInteger(item.BERENCANA),
  //           SIBERANI: convertToInteger(item.SIBERANI),
  //           INFAQ: convertToInteger(item.INFAQ),
  //           DROPING: convertToInteger(item.DROPING),
  //           JMLDROPING: convertToInteger(item.JMLDROPING),
  //           PENDAPATAN: convertToInteger(item.PENDAPATAN)
  //         };
  //       } else {
  //         merged[key].SUKARELA += convertToInteger(item.SUKARELA);
  //         merged[key].BERENCANA += convertToInteger(item.BERENCANA);
  //         merged[key].SIBERANI += convertToInteger(item.SIBERANI);
  //         merged[key].INFAQ += convertToInteger(item.INFAQ);
  //         merged[key].DROPING += convertToInteger(item.DROPING);
  //         merged[key].JMLDROPING += convertToInteger(item.JMLDROPING);
  //         merged[key].PENDAPATAN += convertToInteger(item.PENDAPATAN);
  //       }
  //     });
    
  //     return Object.values(merged);
  //   };

  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_SIRKAH_API_URL}/batch-results/${batchId}`);
  //     if (response.data.success) {
  //       const mergedData = mergeResults(response.data.results);
  
  //       setData(mergedData);
  //       setTableLoading(false);
  //     } else {
  //       // Add some limit or logic to stop retrying indefinitely
  //       setTimeout(() => fetchBatchResults(batchId), 1000);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching batch results:', error);
  //     setTimeout(() => fetchBatchResults(batchId), 1000);
  //   }
  // }, []);

  // Fetch Api
  useEffect(() => {
    const mergeResults = (results) => {
      const merged = {};
    
      results.forEach(item => {
        const key = `${item.NOKANT}_${item.TAHUN}_${item.BULAN}`;
        if (!merged[key]) {
          merged[key] = {
            KANTOR: item.KANTOR,
            TAHUN: item.TAHUN,
            BULAN: getMonthName(item.BULAN),
            SUKARELA: convertToInteger(item.SUKARELA),
            BERENCANA: convertToInteger(item.BERENCANA),
            SIBERANI: convertToInteger(item.SIBERANI),
            INFAQ: convertToInteger(item.INFAQ),
            DROPING: convertToInteger(item.DROPING),
            JMLDROPING: convertToInteger(item.JMLDROPING),
            PENDAPATAN: convertToInteger(item.PENDAPATAN)
          };
        } else {
          merged[key].SUKARELA += convertToInteger(item.SUKARELA);
          merged[key].BERENCANA += convertToInteger(item.BERENCANA);
          merged[key].SIBERANI += convertToInteger(item.SIBERANI);
          merged[key].INFAQ += convertToInteger(item.INFAQ);
          merged[key].DROPING += convertToInteger(item.DROPING);
          merged[key].JMLDROPING += convertToInteger(item.JMLDROPING);
          merged[key].PENDAPATAN += convertToInteger(item.PENDAPATAN);
        }
      });
    
      return Object.values(merged);
    };

    const fetchEmployeeAndPerformanceData = async () => {
      if (idPegawai) {
        try {
          const { data: employeeData } = await axiosPrivate.get(`/pegawai/${idPegawai}`);
          setPegawai(employeeData);
          setTableTitle(`Kinerja ${employeeData?.nama}`);
  
          if (employeeData?.kodePegawai) {
            setTableLoading(true);
            const { data: initialResponse } = await axios.post(`${process.env.REACT_APP_SIRKAH_API_URL}/laporan/laporan-kinerja-petugas/0000`, {
              nik: employeeData.kodePegawai
            });
  
            if (initialResponse.success) {
              const mergedData = mergeResults(initialResponse.data);
  
              setData(mergedData);
              // console.log(initialResponse.batchId);
              // await fetchBatchResults(initialResponse.batchId);
            }
          }
        } catch (error) {
          console.error('Fetching employee or performance data failed:', error);
        } finally {
          setTableLoading(false);
        }
      }
    };
  
    fetchEmployeeAndPerformanceData();
  }, [idPegawai]); // Ensure that pegawai?.kodePegawai is either stable or unnecessary for the dependency array

  return (
    <>
      <Container
        sx={{
          mt: 5
        }}
      >
        <Card>
          <MUIDataTable
            title={tableTitle}
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        hideBackdrop
        open={modalOpen}
        onClose={handleCloseModal}
        style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </>
      </Modal>
    </>
  );
}
