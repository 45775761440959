import saldoCutiFormModel from './saldoCutiFormModel';

const {
  formField: {
    kodePegawai,
    tahun,
    saldo
  }
} = saldoCutiFormModel;

export default {
  [kodePegawai.name]: '',
  [tahun.name]: '',
  [saldo.name]: ''
};
