import userFormModel from './userFormModel';

const {
  formField: {
    firstName,
    lastName,
    email,
    avatar,
    role,
    kantor,
    password,
    repassword,
    status
  }
} = userFormModel;

export default {
  [firstName.name]: '',
  [lastName.name]: '',
  [email.name]: '',
  [avatar.name]: '',
  [role.name]: '',
  [kantor.name]: '',
  [password.name]: '',
  [repassword.name]: '',
  [status.name]: 1
};
