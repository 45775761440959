import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import { NotificationProvider } from './context/notificationContext';
import { AuthContextProvider } from './context/authContext';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import AlertBox from './components/alert';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AuthContextProvider>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <NotificationProvider>
              <AlertBox />
              <Router />
            </NotificationProvider>
          </ThemeProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
