import { useEffect, useState, useMemo } from 'react';
// @mui
import {
  Card,
  Container,
  Button,
  Box,
  Modal,
  IconButton
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { parseISO, endOfDay } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import MUIDataTable from "mui-datatables";
import Label from '../../../components/label';
import { TableLoader } from '../../../components/table';
import ImageComponent from '../../../components/files/image';
import Iconify from '../../../components/iconify';

import { axiosPrivate } from '../../../common/axiosPrivate';

export default function LapDetailAbsenPage({ absen }) {
  const { kodePegawai, nama, startDate, endDate } = absen;
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [modalDetailOpen, setModalDetailOpen] = useState(false);
  const [modalImageOpen, setModalImageOpen] = useState(false);
  const [imageObjectKey, setImageObjectKey] = useState(null);

  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
      name: "tanggal",
      label: "Tanggal",
      options: {
       filter: true,
       sort: true,
       customBodyRender : (value) => format(parseISO(value), 'dd/MM/yyyy')
      }
    },
    {
      name: "jamMasuk",
      label: "Jam Masuk",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) =>
          value && meta.rowData[4]?.toLowerCase() === 'hadir'
            ? format(utcToZonedTime(parseISO(value), 'UTC'), 'HH:mm', {
                timeZone: 'UTC',
              })
            : '-',
      },
    },     
    {
      name: "jamKeluar",
      label: "Jam Keluar",
      options: {
       filter: false,
       sort: false,
       customBodyRender: (value, meta) =>
          value && meta.rowData[4]?.toLowerCase() === 'hadir'
            ? format(utcToZonedTime(parseISO(value), 'UTC'), 'HH:mm', {
                timeZone: 'UTC',
              })
            : '-',
      }
    },
    {
      name: "jenis",
      label: "Absensi",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <Label color={getColorByAbsensi(value)}>{value}</Label>
      }
    },
    {
     name: "keterangan",
     label: "Keterangan",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "tanggalUpdate",
      label: "Tanggal Update",
      options: {
       filter: false,
       sort: false,
       customBodyRender : (value) => value ? format(parseISO(value), 'dd/MM/yyyy HH:mm:ss') : ''
      }
     },
    {
      name: "image",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customHeadRender: () => null,
        customBodyRender: (value) => (
          <Button
              variant="outlined"
              size="small"
              onClick={() => handleOpenModalImage(value)}
              disabled={value === undefined || value === null || value === ""}
            >
            <Iconify icon={'eva:image-fill'} sx={{ marginRight: '4px' }} />
            Foto
          </Button>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `Absen-${nama}-${kodePegawai}-${format(startDate, 'dd-MM-yyyy')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      }
    },
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 50, 100, 200],
  };

  const getColorByAbsensi = (absensiValue) => {
    const colorMap = {
      Alpa: 'error',
      Sakit: 'error',
      Izin: 'error',
      Cuti: 'error',
      'Tidak Absen': 'warning'
      // Add more mappings as needed
    };
  
    return colorMap[absensiValue] || 'success';
  };

  const handleCloseModalDetail = () => {
    setModalDetailOpen(false);
  }

  const handleOpenModalImage = (imageUrl) => {
    setImageObjectKey(imageUrl);
    setModalImageOpen(true);
  }

  const handleCloseModalImage = () => {
    setModalImageOpen(false);
  }

  // Fetch Api
  useEffect(() => {
    const modEndDate = endOfDay(endDate);

    axiosPrivate.post(`/laporan/history-absen-pegawai-detail`, { kodePegawai, startDate, endDate: modEndDate })
    .then((response) => {
      const data = response?.data;
      
      setData(data);
    })
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kodePegawai]);

  return (
    <>
      <Container
        sx={{
          mt: 5
        }}
      >
        <Card>
          <MUIDataTable
            title={`${nama} (${kodePegawai})`}
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>
      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        hideBackdrop
        open={modalDetailOpen}
        onClose={handleCloseModalDetail}
        style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModalDetail}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </>
      </Modal>
      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        open={modalImageOpen}
        onClose={handleCloseModalImage}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }}>
          <ImageComponent
            objectKey={imageObjectKey}
            onClose={handleCloseModalImage}
          />
        </Box>
      </Modal>
    </>
  );
}
