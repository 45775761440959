import React, { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Grid,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { InputField, SelectField, DatePickerField, TimePickerField, AutocompleteField, UploadField } from '../../../components/form-fields';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';

import validationSchema from './form-model/validationSchema';
import absenFormModel from './form-model/absenFormModel';
import formInitialValues from './form-model/formInitialValues';

export default function CabangAbsenForm({data, dropDown, handleCloseModal, handleUpdateTable}) {
  const notificationCtx = useContext(NotificationContext);
  const { formId, formField } = absenFormModel;
  const { tanggal, kodePegawai, jenis, jamMasuk, jamKeluar, hari, image, keterangan } = formField;
  const { REACT_APP_S3_URL } = process.env;
  const [absenHadir, setAbsenHadir] = useState('');

  const _handleInitialValues = data !== undefined ?  {
    kodePegawai: data.kodePegawai,
    tanggal: data.tanggal,
    jenis: data.jenis,
    hari: data.hari,
    jamMasuk: data.jamMasuk,
    jamKeluar: data.jamKeluar,
    image: data.image,
    keterangan: data.keterangan
  } : formInitialValues

  const jenisDropdown = [
    {
      value: 'Hadir',
      label: 'Hadir'
    },
    {
      value: 'Alpa',
      label: 'Alpa'
    },
    {
      value: 'Sakit',
      label: 'Sakit'
    },
    {
      value: 'Izin',
      label: 'Izin'
    },
    {
      value: 'Cuti',
      label: 'Cuti'
    }
  ];

  const handleMessage = (params) => {
    const success = params?.success;

    if (success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: params?.message
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.message
      });
    }
  };

  const validateBase64Image = (base64String) => {
    const ALLOWED_FORMATS = ['jpeg', 'png', 'jpg'];
  
    // Check if string is base64
    if (!base64String.startsWith('data:image/')) {
      return { isValid: false, message: 'Invalid image format' };
    }
  
    // Extract format
    const format = base64String.substring(11, base64String.indexOf(';base64,'));
    if (!ALLOWED_FORMATS.includes(format)) {
      return { isValid: false, message: 'Unsupported image format' };
    }
  
    return { isValid: true, message: 'Valid image' };
  };
  
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const { tanggal, kodePegawai, jenis, jamMasuk, jamKeluar, hari, image, keterangan } = values;
    const baseUrl = new URL(REACT_APP_S3_URL).origin;
    
    const params = {
      qrToken: 'null',
      tanggal,
      jamMasuk,
      jamKeluar,
      latitude: 0,
      longitude: 0,
      kodePegawai,
      jenis,
      hari,
      image,
      keterangan
    };

    if (image && !image.startsWith(baseUrl)) {
      if (image !== '') {
        const { isValid, message } = validateBase64Image(image);
    
        // Show error notification if the image is not valid
        if (!isValid) {
          notificationCtx.setup({
            severity: 'error',
            title: 'Error',
            message
          });
    
          return;
        }
      }
    }

    if(jenis === 'Cuti' && !data?.kodePegawai) {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: 'Silahkan request di menu Request Cuti.'
      });

      return;
    }

    // console.log(JSON.stringify(params));

    const endpoint = data?.kodePegawai
      ? axiosPrivate.post(`/pegawai-absen/update`, params)
      : axiosPrivate.post(`/pegawai-absen/store`, params); 

    try {
      const response = await endpoint;
      const { results } = response?.data;

      handleMessage(response?.data);
      handleUpdateTable(results);

      if (!data?.kodePegawai) {
        resetForm();
      }
    } catch (error) {
      console.log(error);
      handleMessage(error.response.data);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if(data?.jenis === 'Hadir') {
      setAbsenHadir('Hadir');
    }
  }, [data?.jenis]);

  return (
    <Formik
      initialValues={_handleInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <DatePickerField
                name={tanggal.name}
                label={tanggal.label}
                format="dd/MM/yyyy"
                readOnly={Boolean(data?.kodePegawai)}
                maxDate={new Date()}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AutocompleteField
                name={kodePegawai.name}
                label={kodePegawai.label}
                data={dropDown}
                readOnly={!!data}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SelectField
                name={jenis.name}
                label={jenis.label}
                data={jenisDropdown}
                onChange={(event) => setAbsenHadir(event.target.value)}
                fullWidth
              />
            </Grid>
            {absenHadir === 'Hadir' && (
              <>
                <Grid item xs={12} sm={12}>
                  <TimePickerField
                    name={jamMasuk.name}
                    label={jamMasuk.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TimePickerField
                    name={jamKeluar.name}
                    label={jamKeluar.label}
                    fullWidth
                  />
                </Grid>
              </>
            )}
            {absenHadir !== 'Hadir' && (
              <Grid item xs={12} sm={12}>
                <InputField
                  name={hari.name}
                  label={hari.label}
                  readOnly={data?.kodePegawai}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <UploadField
                id={image.id}
                name={image.name}
                label={image.label}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name={keterangan.name}
                label={keterangan.label}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseModal}
            >
              Kembali
            </Button>
            <LoadingButton type="submit" loading={isSubmitting} variant="contained">
              Simpan
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
