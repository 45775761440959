import React from 'react';
import {
  Box,
  IconButton
} from '@mui/material';
import { useField } from 'formik';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CancelIcon from '@mui/icons-material/Cancel';
import { red, blueGrey } from '@mui/material/colors';

import './UploadField.css';

export default function UploadField(props) {
  const { id, label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const { value } = field;

  const onUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        // Desired dimensions
        const maxWidth = 800;
        const maxHeight = 600;
        const canvas = document.createElement('canvas');
        let {width} = img;
        let {height} = img;
  
        // Calculate the new dimensions
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
  
        // Set canvas dimensions
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
  
        // Convert canvas to Base64
        const resizedImage = canvas.toDataURL('image/jpeg', 0.7);
        setValue(resizedImage);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };  

  const onDelete = (e) => {
    e.preventDefault();
    setValue('');
  }

  return (
    <>
      <label htmlFor={id}>
        {label}
        <input
          {...rest}
          hidden
          id={id}
          accept="image/*"
          type="file"
          onChange={onUpload}
        />
        <Box
          className="upload-field-container" // Add a class for styling
        >
          {value ? (
            <Box>
              <img
                src={value}
                alt="Upload Foto"
                className="uploaded-image" // Add a class for styling
              />
              <IconButton
                aria-label="remove picture"
                component="span"
                sx={{
                  color: red['500'],
                  position: 'absolute',
                  top: 0,
                  right: 0
                }}
                onClick={onDelete}
              >
                <CancelIcon />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              aria-label="upload picture"
              component="span"
              sx={{
                color: blueGrey['500']
              }}
            >
              <PhotoCamera />
            </IconButton>
          )}
        </Box>
      </label>
      {meta.touched && meta.error ? (
        <div style={{ color: "red" }}>{meta.error}</div>
      ) : null}
    </>
  );
}
