import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack } from '@mui/material';
import { KotaKabupatenForm } from '../../../sections/@dashboard/kota-kabupaten';

const AddKotaKabupatenPage = ({handleCloseModal, handleUpdateTable}) => (
    <>
      <Helmet>
        <title> Kota/Kabupaten | Sip App </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tambah Kota/Kabupaten
          </Typography>
        </Stack>
        <KotaKabupatenForm
          handleCloseModal={handleCloseModal}
          handleUpdateTable={handleUpdateTable}
        />
      </Container>
    </>
  );
  
export default AddKotaKabupatenPage;
