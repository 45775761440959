import { useState, useEffect } from 'react';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { JabatanForm } from '../../../sections/@dashboard/jabatan';
import { axiosPrivate } from '../../../common/axiosPrivate';

const EditJabatanPage = ({id, handleCloseModal, handleUpdateTable}) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [data, setData] = useState(null);


  useEffect(() => {
    setPageLoading(true);
      axiosPrivate.get(`/jabatan/${id}`)
      .then((response) => setData(response?.data))
      .catch((error) => console.log(error?.response))
      .finally(() => setPageLoading(false));
  }, [id]);

  return (
    <>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
          <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Ubah Jabatan
            </Typography>
          </Stack>
          <JabatanForm
            data={data}
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </Container>
        )}
    </>
  );
};
  
export default EditJabatanPage;
