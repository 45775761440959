import React, { useContext } from 'react';
import moment from 'moment';
import { Grid, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { InputField, UploadDocField, DatePickerField } from '../../../components/form-fields';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';
import AuthContext from '../../../context/authContext';

import validationDokSchema from './form-model/validationDokSchema';
import dokumenFormModel from './form-model/dokumenFormModel';
import fromDokInitialValues from './form-model/fromDokInitialValues';

export default function DokumenForm({data, pegawai, handleCloseModal, handleUpdateTable}) {
  const { user } = useContext(AuthContext);
  const notificationCtx = useContext(NotificationContext);
  const { idPegawai, kodePegawai } = pegawai;
  const { formId, formField } = dokumenFormModel;
  const { dokumen, nama, keterangan, penyerah, penerima, tglSerah, tglTerima } = formField;

  const _handleInitialValues = data !== undefined
    ? {
      dokumen: `https://drive.google.com/file/d/${data.dokumen?.id}/view`,
      nama: data.nama,
      keterangan: data.keterangan,
      penyerah: data.penyerah,
      penerima: data.penerima,
      tglSerah: data.tglSerah,
      tglTerima: data.tglTerima
    }
    : fromDokInitialValues;

  const handleMessage = async (params) => {
    const success = params?.success;

    if (success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: params?.message
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.message
      });
    }
  };

  const _handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const params = {
      kodePegawai,
      dokumenUrl: values?.dokumen,
      nama: values?.nama,
      keterangan: values?.keterangan,
      penyerah: values?.penyerah,
      penerima: values?.penerima,
      tglSerah: values?.tglSerah,
      tglTerima: values?.tglTerima,
      dibuatOleh: user?._id
    };

    // console.log(params);

    if (data?._id) {
      params.tglUbah = moment().format('YYYY-MM-DDTHH:mm:ss');
      params.dokumenId = data?.dokumen?.id;
    } else {
      params.tglBuat = moment().format('YYYY-MM-DDTHH:mm:ss');
    }

    const endpoint = data
      ? axiosPrivate.put(`dokumen/${data._id}`, params)
      : axiosPrivate.post(`dokumen/${idPegawai}`, params);

    try {
      const response = await endpoint;
      const { result } = response?.data;

      handleUpdateTable(result);
      handleMessage(response?.data);

      if (!data?._id) {
        resetForm();
      }
    } catch (error) {
      handleMessage(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={_handleInitialValues}
      validationSchema={validationDokSchema}
      onSubmit={_handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <UploadDocField name={dokumen.name} label={dokumen.label} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField name={nama.name} label={nama.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField name={keterangan.name} label={keterangan.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField name={penerima.name} label={penerima.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField name={penyerah.name} label={penyerah.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DatePickerField
                name={tglTerima.name}
                label={tglTerima.label}
                format="dd/MM/yyyy"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DatePickerField
                name={tglSerah.name}
                label={tglSerah.label}
                format="dd/MM/yyyy"
                fullWidth
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseModal}
            >
              Kembali
            </Button>
            <LoadingButton type="submit" loading={isSubmitting} variant="contained">
              Simpan
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
