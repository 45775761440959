import absenFormModel from './absenFormModel';

const {
  formField: {
    tanggal,
    kodePegawai,
    jenis,
    jamMasuk,
    jamKeluar,
    hari,
    image,
    keterangan
  }
} = absenFormModel;

export default {
  [tanggal.name]: '',
  [kodePegawai.name]: '',
  [jenis.name]: '',
  [jamMasuk.name]: '',
  [jamKeluar.name]: '',
  [hari.name]: '',
  [image.name]: '',
  [keterangan.name]: ''
};
