import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
// @mui
import {
  Card,
  Box,
  Stack,
  Container,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import { id } from "date-fns/locale";
import { format, parseISO, parse, getDate, getMonth } from 'date-fns';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import Iconify from '../../../components/iconify';
import { getCurrentDate, dateDiff } from "../../../utils/formatDate";
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function BirthdayPage() {
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString());
  const [tableLoading, setTableLoading] = useState(true);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
     name: "nama",
     label: "Nama",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "kodePegawai",
     label: "Kode",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "kantor",
      label: "Kantor",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "jabatan",
      label: "Jabatan",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "tglMasuk",
      label: "Lama Kerja",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => getDateDiff(format(parseISO(value), 'yyyy-MM-dd'), getCurrentDate('-'))
      }
    },
    {
      name: "tglLahir",
      label: "Birthday",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value ? format(parseISO(value), 'd MMMM yyyy', { locale: id }) : ''
      }
    },
    {
      name: "profilUrlId",
      label: "Foto",
      options: {
       filter: false,
       sort: false,
       customBodyRender: (value) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleDownloadPhoto(value)}
        >
          <Iconify icon={'eva:download-fill'} />
          Download
        </Button>
       )
      }
     }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `birthday-pegawai-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    },
    setRowProps: (row) => {
      const birthday = row[7];

      if (isBirthdayToday(birthday)) {
        return {
          style: { backgroundColor: 'rgba(255, 193, 7, 0.16)' },
        };
      }
      return {};
    },
  };

  const months = [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
  ];

  const monthOptions = months.map((month, index) => ({
    value: index + 1,
    label: month
  }));

  const handleDownloadPhoto = (profilUrlId) => {
    // Construct the direct download link of the image
    const downloadLink = `https://drive.google.com/uc?id=${profilUrlId}&export=download`;

    // Create a temporary anchor element
    const anchor = document.createElement("a");

    // Set attributes for download
    anchor.href = downloadLink;
    anchor.setAttribute("download", "photo.jpg");

    // Trigger download by simulating a click event on the anchor element
    anchor.click();
  }

  const handleChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const getDateDiff = (startDate, endDate) => {
    const { years, months, days } = dateDiff(startDate, endDate);

    let result = '';
    if (years > 0) {
        result += `${years} Tahun `;
    }
    if (months > 0) {
        result += `${months} Bulan `;
    }
    if (days > 0) {
        result += `${days} Hari `;
    }

    return result.trim(); // Remove trailing space
  };

  const isBirthdayToday = (birthday) => {
    const dateFormat = 'd MMMM yyyy';
    
    try {
      const birthdayDate = parse(birthday, dateFormat, new Date(), { locale: id });
    
      const todayFormatted = format(new Date(), 'MM-dd');
      const birthdayFormatted = format(birthdayDate, 'MM-dd');
      
      return todayFormatted === birthdayFormatted;
    } catch (error) {
      console.error("Error parsing date:", error);
      return false;
    }
  };

  const isSortingBirthdayToday = (isoDateString) => {
    const birthday = parseISO(isoDateString);
    const today = new Date();
  
    return getMonth(birthday) === getMonth(today) && getDate(birthday) === getDate(today);
  };

  // Fetch Api
  useEffect(() => {
    const fetchBirthdayData = async () => {
      setTableLoading(true);
      try {
        const response = await axiosPrivate.post('pegawai/birthday', { bulan: parseInt(selectedMonth, 10) });
        const fetchedData = response.data;
  
        // Sort data so that today's birthdays are first
        const sortedData = fetchedData.sort((a, b) => {
          const aIsToday = isSortingBirthdayToday(a.tglLahir);
          const bIsToday = isSortingBirthdayToday(b.tglLahir);
  
          if (aIsToday && !bIsToday) {
            return -1;
          }
          if (!aIsToday && bIsToday) {
            return 1;
          }
          return 0; // Keep original order if neither or both are today
        });
  
        setData(sortedData);
      } catch (error) {
        console.error(error);
      } finally {
        setTableLoading(false);
      }
    };
  
    fetchBirthdayData();
  }, [selectedMonth]);
  

  return (
    <>
      <Helmet>
        <title> Birthday Pegawai | Sip App </title>
      </Helmet>

      <Container
        sx={{
          mt: 5
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="start">
            <Box sx={{ marginRight: 2 }}>
              <Select
                value={selectedMonth}
                onChange={handleChange}
              >
                {monthOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Stack>
        </Box>
        <Card>
          <MUIDataTable
            title="Birthday Pegawai"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>
    </>
  );
}
