import mutasiFormModel from './promosiFormModel';

const {
  formField: {
    kodePegawai,
    dariJabatan,
    keJabatan,
    tglMulai,
    tglAkhir,
    keterangan
  }
} = mutasiFormModel;

export default {
  [kodePegawai.name]: '',
  [dariJabatan.name]: '',
  [keJabatan.name]: '',
  [tglMulai.name]: '',
  [tglAkhir.name]: '',
  [keterangan.name]: ''
};
