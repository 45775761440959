import jabatanFormModel from './jabatanFormModel';

const {
  formField: {
    kode,
    nama,
    grup
  }
} = jabatanFormModel;

export default {
  [kode.name]: '',
  [nama.name]: '',
  [grup.name]: ''
};
