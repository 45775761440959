import React, { useContext } from 'react';
import moment from 'moment';
import {
  Grid,
  Button,
  Stack
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { InputField } from '../../../components/form-fields';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';
import AuthContext from '../../../context/authContext';

import validationSchema from './form-model/validationSchema';
import kotaKabFormModel from './form-model/kotaKabFormModel';
import formInitialValues from './form-model/formInitialValues';

export default function KotaKabupatenForm({data, handleCloseModal, handleUpdateTable}) {
  const { user } = useContext(AuthContext);
  const notificationCtx = useContext(NotificationContext);
  const { formId, formField } = kotaKabFormModel;
  const { kode, nama } = formField;

  const _handleInitialValues = data !== undefined ?  {
      kode: data.kode,
      nama: data.nama
    } : formInitialValues


  const handleMessage = (params) => {
    if(params?.success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: data?._id ? 'Data Berhasil Diubah.' : 'Data Berhasil Dibuat.'
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.response
      })
    }
  }

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const params = data?._id ? {
      kode: values?.kode,
      nama: values?.nama,
      dibuatOleh: user?._id,
      tglUbah: moment().format('YYYY-MM-DDTHH:mm:ss')
    } : {
      kode: values?.kode,
      nama: values?.nama,
      dibuatOleh: user?._id,
      tglBuat: moment().format('YYYY-MM-DDTHH:mm:ss')
    }
    const endpoint = data ? axiosPrivate.put(`/kotakab/${data?._id}`, params) : axiosPrivate.post(`/kotakab`, params);

    await endpoint
    .then((response) => {
      const { result } = response?.data;

      handleUpdateTable(result);
      handleMessage(response?.data);

      if(!data?._id) {
        resetForm()
      }
    })
    .catch((error) => handleMessage(error))
    .finally(() => {
      setSubmitting(false);
    });
  }

  return (
    <>
      <Formik
        initialValues={_handleInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form id={formId}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <InputField name={kode.name} label={kode.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputField name={nama.name} label={nama.label} fullWidth />
              </Grid>
            </Grid>
            <Stack
              direction="row"
              justifyContent="end"
              mt={2}
              spacing={1}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseModal}
              >
                Kembali
              </Button>
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
              >
                Simpan
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
}
