import * as Yup from 'yup';
import mutasiFormModel from './mutasiFormModel';

const {
  formField: {
    kodePegawai,
    dariKantor,
    keKantor,
    tglMulai
  }
} = mutasiFormModel;

export default Yup.object({
  [kodePegawai.name]: Yup.string().required(`${kodePegawai.requiredErrorMsg}`),
  [dariKantor.name]: Yup.string().required(`${dariKantor.requiredErrorMsg}`),
  [keKantor.name]: Yup.string().required(`${keKantor.requiredErrorMsg}`),
  [tglMulai.name]: Yup.string().required(`${tglMulai.requiredErrorMsg}`)
});
