import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { NumericFormat } from 'react-number-format';

function SelectField(props) {
  const { label, name, data, readOnly, onChange, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const _renderHelperText = () => {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }

    return false;
  }

  const handleChange = (event) => {
    helpers.setValue(event.target.value); // Update Formik field value
    if (onChange) {
      onChange(event); // Call the provided onChange function
    }
  };

  return (
    <FormControl {...rest} error={isError}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        label={label}
        value={selectedValue || ''}
        inputProps={{ readOnly }}
        onChange={handleChange}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {name === 'gaji' ? (<NumericFormat displayType="text" value={parseFloat(item.label)} thousandSeparator="," />) : item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

SelectField.defaultProps = {
  data: []
};

SelectField.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func
};

export default SelectField;
