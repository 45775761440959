import kotaKabFormModel from './kotaKabFormModel';

const {
  formField: {
    kode,
    nama
  }
} = kotaKabFormModel;

export default {
  [kode.name]: '',
  [nama.name]: ''
};
