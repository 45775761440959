import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { DokumenAllForm } from "../../../sections/@dashboard/dokumen";
import { axiosPrivate } from '../../../common/axiosPrivate';

const EditDokPegawaiPage = ({id, handleCloseModal, handleUpdateTable}) => {
  const [data, setData] = useState({});
  const [pegawais, setPegawais] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    const getDokumen = async () => {
      await axiosPrivate.get(`dokumen/show/${id}`)
      .then((response) => {
        const data = response?.data;
        
        setData(data);
      })
      .catch((error) => console.log(error));
    }

    getDokumen()
    .finally(() => {
      axiosPrivate.get('dokumen/dropdown/list')
    .then((response) => setPegawais(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setPageLoading(false));
    });
  }, [id]);

  return (
    <>
      <Helmet>
        <title> Ubah Dokumen | Sip App </title>
      </Helmet>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
          <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Ubah Dokumen
            </Typography>
          </Stack>
          <DokumenAllForm
            data={data}
            pegawais={pegawais}
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </Container>
        )}
    </>
  );
};
  
export default EditDokPegawaiPage;
