import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
import axios from "axios";
// @mui
import {
  Card,
  Container,
  Box,
  TableCell,
  TableFooter,
  TableRow
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NumericFormat } from 'react-number-format';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import { getCurrentDate } from "../../../utils/formatDate";

export default function LapKinerjaPage() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const useStyles = makeStyles(() => ({
    footerCell: {
      backgroundColor: "#ebeef2",
      fontSize: 14,
      fontWeight: "bolder"
    }
  }));

  const classes = useStyles();

  const columns = [
    {
      name: "NUMBER",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
      name: "KDTELR",
      label: "Kode Teler",
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "NMTELR",
      label: "Nama Teler",
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "KANTOR",
      label: "kantor",
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "JABATAN",
      label: "Jabatan",
      options: {
       filter: true,
       sort: true,
      }
    },
    {
     name: "TAHUN",
     label: "Tahun",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "BULAN",
      label: "Bulan",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "DEBDRP",
      label: "Droping",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "JMLDROPING",
      label: "Jml Orang",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "SIMPKK",
      label: "S. PKK",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "ANGPKK",
      label: "Ang. PKK",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "ANGMGN",
      label: "Ang. MGN",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "TRA4PR",
      label: "Wajib 5%",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "TRAKBJ",
      label: "Kebajikan",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "TRAINF",
      label: "Infaq",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "TRAADM",
      label: "Admin",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "TRAMGO",
      label: "Minggon",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "SUKARELA",
      label: "Sukarela",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "BERENCANA",
      label: "Berencana",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "SIBERANI",
      label: "Siberani",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "TRASPP",
      label: "Asuransi",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "TRATAW",
      label: "Taawun",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    },
    {
      name: "MUQASA",
      label: "Muqasa",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "right"
        }),
        customBodyRender: (value) => (
          <>
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          </>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `kinerja-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setRowsPerPage(numberOfRows);
    },
    onChangePage(page) {
      setPage(page);
    },
    customTableBodyFooterRender: (opts) => {
      const startIndex = page * rowsPerPage;
      const endIndex = (page + 1) * rowsPerPage;
      // console.log(opts.data);
      const sumDrop = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[7].props.children.props.children.props.value), 0);

      const sumJmlDrop = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[8].props.children.props.children.props.value), 0);
    
      const sumSpkk = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[9].props.children.props.children.props.value), 0);

      const sumAngpkk = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[10].props.children.props.children.props.value), 0);

      const sumAngMgn = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[11].props.children.props.children.props.value), 0);
      
      const sumTra4pr = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[12].props.children.props.children.props.value), 0);

      const sumTraKbj = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[13].props.children.props.children.props.value), 0);

      const sumTraInf = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[14].props.children.props.children.props.value), 0);

      const sumTraAdm = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[15].props.children.props.children.props.value), 0);

      const sumTraMgo = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[16].props.children.props.children.props.value), 0);

      const sumSukarela = opts.data
        .slice(startIndex, endIndex)
        .reduce((value, item) => value + parseFloat(item.data[17].props.children.props.children.props.value), 0);

      const sumBerencana = opts.data
        .slice(startIndex, endIndex)
        ?.reduce((value, item) => value + parseFloat(item.data[18].props.children.props.children.props.value), 0);

      const sumSiberani = opts.data
        .slice(startIndex, endIndex)
        ?.reduce((value, item) => value + parseFloat(item.data[19].props.children.props.children.props.value), 0);
      
      const sumTraSpp = opts.data
        .slice(startIndex, endIndex)
        ?.reduce((value, item) => value + parseFloat(item.data[20].props.children.props.children.props.value), 0);

      const sumTraTaw = opts.data
        .slice(startIndex, endIndex)
        ?.reduce((value, item) => value + parseFloat(item.data[21].props.children.props.children.props.value), 0);

      const sumMuqasa = opts.data
        .slice(startIndex, endIndex)
        ?.reduce((value, item) => value + parseFloat(item.data[22].props.children.props.children.props.value), 0);
      
      return (
        <>
          {data.length > 0 && (
            <TableFooter className={classes.footerCell}>
              <TableRow>
                {opts.columns.map((col, index) => {
                  // console.log(col.name);
                  if (col.display === "true") {
                    if (col.name === "NUMBER") {
                      return (
                        <TableCell key={index} className={classes.footerCell} colSpan={7} align='center'>
                          Total
                        </TableCell>
                      );
                    } if (col.name === "DEBDRP") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumDrop) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumDrop).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "JMLDROPING") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumJmlDrop) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumJmlDrop).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "SIMPKK") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumSpkk) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumSpkk).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "ANGPKK") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumAngpkk) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumAngpkk).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "ANGMGN") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumAngMgn) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumAngMgn).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "TRA4PR") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumTra4pr) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumTra4pr).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "TRAKBJ") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumTraKbj) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumTraKbj).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "TRAINF") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumTraInf) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumTraInf).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "TRAADM") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumTraAdm) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumTraAdm).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "TRAMGO") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumTraMgo) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumTraMgo).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    } if (col.name === "SUKARELA") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumSukarela) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumSukarela).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    }
                    if (col.name === "BERENCANA") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumBerencana) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumBerencana).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    }
                    if (col.name === "SIBERANI") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumSiberani) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumSiberani).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    }
                    if (col.name === "TRASPP") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumTraSpp) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumTraSpp).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    }
                    if (col.name === "TRATAW") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumTraTaw) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumTraTaw).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    }
                    if (col.name === "MUQASA") {
                      return (
                        <TableCell key={index} className={classes.footerCell} align='right'>
                          <Box sx={{ color: parseFloat(sumMuqasa) < 0 ? 'error.main' : 'inherit' }}>
                            <NumericFormat value={parseFloat(sumMuqasa).toFixed(0)} displayType={'text'} thousandSeparator />
                          </Box>
                        </TableCell>
                      );
                    }
                  }

                  return null;
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    }
  };

  // Function to convert month number to month name
  function getMonthName(monthNumber) {
    const months = [
        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    return months[monthNumber - 1] || "";
  }

  // Function to convert string numbers to integers
  function convertToInteger(value) {
    const floatValue = parseFloat(value);
    return Number.isNaN(floatValue) ? value : Math.round(floatValue);
  }

  // Fetch Api
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SIRKAH_API_URL}/laporan/laporan-kinerja-petugas`)
    .then((response) => {
      // console.log(JSON.stringify(response?.data));
      if(response?.data.success) {
        const data = response?.data.data;
        const sortData = data.sort((a, b) => a.BULAN - b.BULAN);
        const modifiedData = sortData.map(item => ({
          "KDTELR": item.KDTELR,
          "NMTELR": item.NMTELR,
          "JABATAN": item.JABATAN,
          "KANTOR": item.NMKANT,
          "TAHUN": item.TAHUN,
          "BULAN": getMonthName(item.BULAN),
          "DEBDRP": convertToInteger(item.DEBDRP),
          "JMLDROPING": convertToInteger(item.JMLDROPING),
          "SIMPKK": convertToInteger(item.SIMPKK),
          "ANGPKK": convertToInteger(item.ANGPKK),
          "ANGMGN": convertToInteger(item.ANGMGN),
          "TRA4PR": convertToInteger(item.TRA4PR),
          "TRAKBJ": convertToInteger(item.TRAKBJ),
          "TRAINF": convertToInteger(item.TRAINF),
          "TRAADM": convertToInteger(item.TRAADM),
          "TRAMGO": convertToInteger(item.TRAMGO),
          "BYRMGN": convertToInteger(item.BYRMGN),
          "SUKARELA": convertToInteger(item.TRASKR),
          "BERENCANA": convertToInteger(item.TRABRC),
          "SIBERANI": convertToInteger(item.SIBERANI),
          "TRASPP": convertToInteger(item.TRASPP),
          "TRATAW": convertToInteger(item.TRATAW),
          "MUQASA": convertToInteger(item.MUQASA)
        }));

        setData(modifiedData);
      }
    })
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false));
  }, []);

  return (
    <>
      <Helmet>
        <title> Laporan Kinerja | Sip App </title>
      </Helmet>

      <Container>
        <Card>
          <MUIDataTable
            title="Laporan Kinerja"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>
    </>
  );
}
