import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo, useContext } from 'react';
// @mui
import {
  Card,
  Container,
  Box,
  Modal,
  Button
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import Label from '../../../components/label';
import { getCurrentDate } from "../../../utils/formatDate";
import Iconify from '../../../components/iconify';
import ImageComponent from '../../../components/files/image';
import AuthContext from '../../../context/authContext';
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function HistoryCutiPage() {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [modalImageOpen, setModalImageOpen] = useState(false);
  const [imageObjectKey, setImageObjectKey] = useState(null);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
     name: "namaPegawai",
     label: "Nama",
     options: {
      filter: true,
      sort: true
     }
    },
    {
     name: "kodePegawai",
     label: "Kode",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "dariTanggal",
      label: "Dari Tanggal",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "khusus",
      label: "Cuti Khusus",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value ? capitalizeFirstLetter(value) : 'Tidak'
      }
    },
    {
      name: "hari",
      label: "Jumlah Hari",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "approved",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          names: ['Approved', 'Rejected', 'By Processed'],
          logic(approved, filters) {
            const statusMap = {
              '1': 'Approved',
              '2': 'Rejected',
              '3': 'By Processed'
            };
    
            const label = statusMap[approved];
            return !filters.includes(label);
          }
        },
        customBodyRender: (data) => {
          let label;
          let color;
    
          switch (data) {
            case '1':
              label = 'Approved';
              color = 'success';
              break;
            case '2':
              label = 'Rejected';
              color = 'error';
              break;
            case '3':
              label = 'By Processed';
              color = 'success';
              break;
            default:
              label = 'Unknown';
              color = 'default';
          }
    
          return <Label color={color}>{label}</Label>;
        }
      }
    },
    {
      name: "keterangan",
      label: "Keterangan",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "approvedDescription",
      label: "Tinjauan",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "attachment",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customHeadRender: () => null,
        customBodyRender: (value) => (
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleOpenModalImage(value)}
            disabled={value === undefined || value === null || value === ""}
          >
            <Iconify icon={'ant-design:paper-clip-outlined'} sx={{ marginRight: '4px' }} />
            Attachment
          </Button>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `req-cuti-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const handleOpenModalImage = (imageUrl) => {
    setImageObjectKey(imageUrl);
    setModalImageOpen(true);
  }

  const handleCloseModalImage = () => {
    setModalImageOpen(false);
  }

  // Fetch Api
  useEffect(() => {
    axiosPrivate.get(`laporan/history-cuti/${user?.kantor}`)
    .then((response) => setData(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, [user?.kantor]);

  return (
    <>
      <Helmet>
        <title> List History Cuti | Sip App </title>
      </Helmet>

      <Container>
        <Card>
          <MUIDataTable
            title="List History Cuti"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        open={modalImageOpen}
        onClose={handleCloseModalImage}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }}>
          <ImageComponent
            objectKey={imageObjectKey}
            onClose={handleCloseModalImage}
          />
        </Box>
      </Modal>
    </>
  );
}
