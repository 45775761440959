import React, { useEffect } from 'react';
import { useField } from 'formik';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { id } from "date-fns/locale";
import { parseISO, isValid } from 'date-fns';

export default function DatePickerField(props) {
  const { fullWidth } = props;
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 1, 0, 0, 0));

      setValue(utcDate.toISOString());
    }
  }, [setValue, value]);

  const _onChange = (date) => {
    if (date instanceof Date && isValid(date)) {
      try {
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 1, 0, 0, 0));

        // Set the value using the ISO string of the adjusted date
        setValue(utcDate.toISOString());
      } catch (error) {
        console.error('Error converting date to ISO string:', error);
        setValue(null);
      }
    } else {
      console.error('Invalid date input:', date);
      setValue(null);
    }
  }
  
  return (
    <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
      <DatePicker 
        {...field}
        {...props}
        autoOk
        value={field.value ? parseISO(field.value) : null}
        onChange={_onChange}
        slotProps={{
          textField: {
            fullWidth,
            variant: 'outlined',
            error: isError,
            helperText: isError && error,
          },
          actionBar: { actions: ['clear'] },
        }}
      />
    </LocalizationProvider>
  );
}
