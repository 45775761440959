import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { UserForm } from '../../sections/@dashboard/user';
import { axiosPrivate } from '../../common/axiosPrivate';

const EditUserPage = ({id, handleCloseModal, handleUpdateTable}) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [dropDown, setDropDown] = useState([]);
  const [data, setData] = useState(null);

  useEffect(() => {
    setPageLoading(true);
    const getDropdown = async () => {
      await axiosPrivate.get('user/dropdown/list')
      .then((response) => {
        const data = response?.data;
        
        setDropDown(data);
      })
      .catch((error) => console.log(error));
    }

    getDropdown()
    .finally(() => {
      axiosPrivate.get(`user/${id}`)
      .then((response) => setData(response?.data))
      .catch((error) => console.log(error?.response))
      .finally(() => setPageLoading(false));
    });
  }, [id]);

  return (
    <>
      <Helmet>
        <title> User | Sip App </title>
      </Helmet>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
          <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Ubah User
            </Typography>
          </Stack>
          <UserForm
            data={data}
            kantorDropDown={dropDown}
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </Container>
        )}
    </>
  );
};
  
export default EditUserPage;
