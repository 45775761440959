import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Avatar,
  ListItemAvatar,
  ListItemText
} from '@mui/material';

function SelectFieldAvatar(props) {
  const { label, data, readOnly, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const _renderHelperText = () => {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }

    return false;
  }

  return (
    <FormControl {...rest} error={isError}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        label={label}
        value={selectedValue || ''}
        inputProps={{ readOnly }}
        sx={{
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          }
        }}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            <ListItemAvatar>
              <Avatar alt='avatar-img' src={`/assets/images/avatars/${item.value}.jpg`} imgProps={{ referrerPolicy: "no-referrer" }} />
            </ListItemAvatar>
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

SelectFieldAvatar.defaultProps = {
  data: []
};

SelectFieldAvatar.propTypes = {
  data: PropTypes.array
};

export default SelectFieldAvatar;
