import React, { useContext } from 'react';
import {
  Stack,
  Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import { InputField, DatePickerField } from '../../../../components/form-fields';

import { axiosPrivate } from '../../../../common/axiosPrivate';
import NotificationContext from '../../../../context/notificationContext';

import validationSchema from './form-model/validationSchema';
import ProcessCutiFormModel from './form-model/processCutiFormModel';
import formInitialValues from './form-model/formInitialValues';

export default function ProcessCutiForm() {
  const notificationCtx = useContext(NotificationContext);
  const { formId, formField } = ProcessCutiFormModel;
  const { dariTanggal, hari, keterangan } = formField;

  const handleMessage = async (params) => {
    const success = params?.success;

    if (success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: params?.message
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.message
      });
    }
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const { dariTanggal, hari, keterangan } = values;
    const params = {
      dariTanggal,
      hari,
      keterangan
    };

    const endpoint = axiosPrivate.post(`req-cuti/process/cuti`, params);

    try {
      const response = await endpoint;

      handleMessage(response?.data);
      resetForm();
    } catch (error) {
      handleMessage(error?.response?.data);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <DatePickerField
                name={dariTanggal.name}
                label={dariTanggal.label}
                format="dd/MM/yyyy"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name={hari.name}
                label={hari.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name={keterangan.name}
                label={keterangan.label}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <LoadingButton type="submit" loading={isSubmitting} variant="contained">
              Proses
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
