export default {
  formId: 'mutasiForm',
  formField: {
    kodePegawai: {
      name: 'kodePegawai',
      label: 'Pegawai*',
      requiredErrorMsg: 'Pegawai harus dipilih'
    },
    dariJabatan: {
      name: 'dariJabatan',
      label: 'Dari Jabatan*',
      requiredErrorMsg: 'Dari jabatan harus dipilih'
    },
    keJabatan: {
      name: 'keJabatan',
      label: 'Ke Jabatan*',
      requiredErrorMsg: 'Ke Jabatan harus dipilih'
    },
    tglMulai: {
      name: 'tglMulai',
      label: 'Tgl Mulai*',
      requiredErrorMsg: 'Tanggal mulai harus dipilih'
    },
    tglAkhir: {
      name: 'tglAkhir',
      label: 'Tgl Akhir',
    },
    keterangan: {
      name: 'keterangan',
      label: 'Keterangan'
    }
  }
};
