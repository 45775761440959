import { useEffect, useState } from 'react';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { PromosiForm } from '../../../sections/@dashboard/promosi';
import { axiosPrivate } from '../../../common/axiosPrivate';

const AddPromosiPage = ({handleCloseModal, handleUpdateTable}) => {
  const [dropDown, setDropDown] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    axiosPrivate.get('promosi/dropdown/list')
    .then((response) => setDropDown(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setPageLoading(false));
  }, []);

  return (
    <>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
          <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Tambah Promosi
            </Typography>
          </Stack>
          <PromosiForm
            dropDown={dropDown}
            handleCloseModal={handleCloseModal}
            handleUpdateTable={handleUpdateTable}
          />
        </Container>
      )}
    </>
  );
}
  
export default AddPromosiPage;
