import { useState, useEffect } from 'react';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image as PDFImage
} from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns';
import { getCurrentDate, dateDiff } from '../../utils/formatDate';
import { axiosPrivate } from '../../common/axiosPrivate';

const PDFDocument = ({ data }) => {
  const { pegawai, promosi, mutasi, penghargaan } = data;
  const [photoBase64, setPhotoBase64] = useState('');

  useEffect(() => {
    const fetchPhoto = async () => {
        try {
            const response = await axiosPrivate.get(`/pegawai/get-file-from-drive/${pegawai.profilUrlId}`);
            const img = new Image();
            img.onload = () => {
                // Handle image loading success
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
              const dataURL = canvas.toDataURL();
              setPhotoBase64(dataURL);
            };
            img.onerror = (error) => {
                throw new Error(`Error loading image, ${error}`);
            };
            img.src = `data:image/jpeg;base64,${response.data.image}`;
        } catch (error) {
            console.error('Error fetching or fixing photo:', error);
        }
    };

    fetchPhoto();
  }, [pegawai.profilUrlId]);

  const getDateDiff = (startDate, endDate) => {
    const { years, months, days } = dateDiff(startDate, endDate);

    let result = '';
    if (years > 0) {
        result += `${years} Tahun `;
    }
    if (months > 0) {
        result += `${months} Bulan `;
    }
    if (days > 0) {
        result += `${days} Hari `;
    }

    return result.trim();
  };

  const renderDetailRow = (title, content) => (
    <View style={styles.detailRow}>
      <Text style={styles.detailTitle}>{`${title} :`}</Text>
      <Text style={styles.detailContent}>{content || ''}</Text>
    </View>
  );

  const formatDate = (TAHUN, BULAN) => {
    const formattedDate = TAHUN && BULAN 
      ? format(new Date(`${TAHUN}-${BULAN}`), 'MMM yyyy') 
      : '';

    return formattedDate;
  }

  const getKategori = (kategori) => {
    switch (kategori) {
      case "1":
        return "Kinerja Petugas Simpanan Terbaik";
      case "2":
        return "Kinerja Petugas Pendapatan Terbaik";
      case "3":
        return "Kinerja Petugas Penyaluran Terbaik";
      case "4":
        return "Kinerja Petugas Infaq Terbaik";
      case "5":
        return "Kinerja Petugas TKB Terbaik";
      default:
        return "Kategori Tidak Dikenal";
    }
  };

  // Render the PDF content based on received data
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.profileContainer}>
          {photoBase64 && <PDFImage
            style={styles.image}
            src={photoBase64}
          />}
          <View>
            <Text style={styles.profileTitle}>{pegawai?.nama}</Text>
            <Text style={styles.profileSubTitle}>{pegawai?.email}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Profil</Text>
          {renderDetailRow('Nama Tanpa Gelar', pegawai.namaTanpaGelar)}
          {renderDetailRow('Jenis Kelamin', pegawai.jenisKelamin)}
          {renderDetailRow('Status Perkawinan', pegawai.statusPerkawinan)}
          {renderDetailRow('Pendidikan', pegawai.pendidikan)}
          {renderDetailRow('Agama', pegawai.agama)}
          {renderDetailRow('Kecamatan', pegawai.kecamatan)}
          {renderDetailRow('Kota/Kabupaten', pegawai.kotaKabupaten)}
          {renderDetailRow('Alamat', pegawai.alamat)}
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Akun</Text>
          {renderDetailRow('No. Rekening', pegawai.noRekening)}
          {renderDetailRow('No. Handphone', pegawai.noHp)}
          {renderDetailRow('NIK', pegawai.nik)}
          {renderDetailRow('NPWP', pegawai.npwp)}
          {renderDetailRow('BPJS', pegawai.bpjs)}
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Kepegawaian</Text>
          {renderDetailRow('Tanggal Masuk', pegawai.tglMasuk ? format(parseISO(pegawai.tglMasuk), 'dd/MM/yyyy') : '')}
          {renderDetailRow('Kantor', pegawai.kantor)}
          {renderDetailRow('Jabatan', pegawai.jabatan)}
          {renderDetailRow('Status Pegawai', pegawai.statusPegawai)}
          {renderDetailRow('Tanggal Status', pegawai.tglStatus ? format(parseISO(pegawai.tglStatus), 'dd/MM/yyyy') : '')}
          {renderDetailRow('Lama Kerja', getDateDiff(format(parseISO(pegawai.tglMasuk), 'yyyy-MM-dd'), getCurrentDate('-')))}
        </View>

        {promosi.length > 0 && <View style={styles.section}>
          <Text style={styles.sectionTitle}>Promosi</Text>
          {promosi && promosi.map((item, index) => (
            <View key={index} style={styles.detailRow}>
              <Text style={styles.detailContent}>{`${item.tglMulai ? format(parseISO(item.tglMulai), 'dd MMM yyyy') : ''}: ${item.dariJabatan} ke ${item.keJabatan}`}</Text>
            </View>
          ))}
        </View>}

        {mutasi.length > 0 && <View style={styles.section}>
          <Text style={styles.sectionTitle}>Mutasi</Text>
          {mutasi && mutasi.map((item, index) => (
            <View key={index} style={styles.detailRow}>
              <Text style={styles.detailContent}>{`${item.tglMulai ? format(parseISO(item.tglMulai), 'dd MMM yyyy') : ''}: ${item.dariKantor} ke ${item.keKantor}`}</Text>
            </View>
          ))}
        </View>}

        {penghargaan.length > 0 && <View style={styles.section}>
          <Text style={styles.sectionTitle}>Penghargaan</Text>
          {penghargaan && penghargaan.map((item, index) => (
            <View key={index} style={styles.detailRow}>
              <Text style={styles.detailContent}>{`${formatDate(item.TAHUN, item.BULAN)}: Penghargaan ${getKategori(item.KATEGORI)}, ${item.NMKANT}`}</Text>
            </View>
          ))}
        </View>}
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 40,
  },
  profileContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  image: {
    width: 90,
    marginRight: 16,
  },
  section: {
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 16,
    marginBottom: 8,
    color: '#000000',
    fontWeight: 'bold',
  },
  detailRow: {
    flexDirection: 'row',
    marginBottom: 4,
  },
  profileTitle: {
    fontSize: 18,
    marginBottom: 4,
  },
  profileSubTitle: {
    fontSize: 12,
    color: '#525252',
  },
  detailTitle: {
    fontSize: 10,
    width: '30%',
    color: '#525252',
  },
  detailContent: {
    fontSize: 10,
    color: '#525252',
  },
  // Add more styles as needed
});

export default PDFDocument;
