import React, { useRef, useState } from 'react';
import { Stack, ButtonGroup, Button, Paper } from '@mui/material';

const CanvasSignature = ({handleCloseModal, handleSetValue}) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const getMousePosition = (e) => {
    const canvas = canvasRef.current;
    const canvasRect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / canvasRect.width;
    const scaleY = canvas.height / canvasRect.height;

    if (e.type === 'mousedown' || e.type === 'mousemove') {
      return {
        x: (e.clientX - canvasRect.left) * scaleX,
        y: (e.clientY - canvasRect.top) * scaleY,
      };
    } if (e.type === 'touchstart' || e.type === 'touchmove') {
      const touch = e.touches[0];
      return {
        x: (touch.clientX - canvasRect.left) * scaleX,
        y: (touch.clientY - canvasRect.top) * scaleY,
      };
    }

    return { x: 0, y: 0 };
  };

  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const { x, y } = getMousePosition(e);

    context.beginPath();
    context.moveTo(x, y);

    setIsDrawing(true);
  };

  const endDrawing = () => {
    setIsDrawing(false);
  };

  const draw = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.lineCap = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = 2;
    const { x, y } = getMousePosition(e);

    context.lineTo(x, y);
    context.stroke();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const saveCanvasImage = () => {
    const canvas = canvasRef.current;
    const base64Image = canvas.toDataURL('image/png');
    console.log('Base64 Image:', base64Image);
    handleSetValue(base64Image);
    handleCloseModal();
  };

  return (
    <>
      <Paper elevation={3} style={{ height: '300px', padding: '16px', textAlign: 'center', position: 'relative' }}>
        <canvas
          ref={canvasRef}
          style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
          onMouseDown={startDrawing}
          onMouseUp={endDrawing}
          onMouseMove={draw}
          onMouseLeave={endDrawing}
          onTouchStart={startDrawing}
          onTouchEnd={endDrawing}
          onTouchMove={draw}
        />
      </Paper>
      <Stack direction="row" justifyContent="center" mt={2} spacing={1}>
        <ButtonGroup variant="contained" color="primary">
          <Button onClick={clearCanvas}>Clear</Button>
          <Button onClick={saveCanvasImage}>Simpan</Button>
        </ButtonGroup>
      </Stack>
    </>
  );
};

export default CanvasSignature;
