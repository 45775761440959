import * as Yup from 'yup';
import kotaKabFormModel from './kotaKabFormModel';

const {
  formField: {
    kode,
    nama
  }
} = kotaKabFormModel;

export default Yup.object({
  [kode.name]: Yup.string().required(`${kode.requiredErrorMsg}`),
  [nama.name]: Yup.string().required(`${nama.requiredErrorMsg}`)
});
