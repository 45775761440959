import { useState, useRef } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField, InputAdornment, IconButton, } from '@mui/material';
// components
import Iconify from '../iconify';

export default function InputPasswordField(props) {
  const { ...rest } = props;
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);
  const password = useRef(null);

  const _renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }

    return false;
  }

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      inputRef={password}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
    />
  );
}
