import { Helmet } from 'react-helmet-async';
import { useState, useMemo } from 'react';
// @mui
import {
  Card,
  Container,
  Stack,
  Select,
  Button,
  MenuItem,
  Box
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import { NumericFormat } from 'react-number-format';
import axios from "axios";
import { TableLoader } from '../../../components/table';

// Function defined outside the component
const generateYearOptions = (startYear, endYear) => {
  const years = [];
  // eslint-disable-next-line no-plusplus
  for (let year = startYear; year <= endYear; year++) {
    years.push({ value: year, label: `${year}` });
  }
  return years;
};

// Function to generate column definitions dynamically
const generateColumns = (data) => {
  if (data.length === 0) {
    return [];
  }

  // Assuming all items have the same structure, so we'll use the first item to extract keys
  const keys = Object.keys(data[0]);

  // Optionally, define keys to exclude from the columns
  const excludeKeys = ["nourut"];

  // Customize this mapping as necessary, for example:
  const columnNamesMap = {
    jnakun: "No",
    ketera: "Indikator",
    nokant1001: "Serang",
    nokant1002: "Taktakan",
    nokant1003: "Kramatwatu",
    nokant1004: "Curug",
    nokant1005: "Ciruas",
    nokant1006: "Pamarayan",
    nokant1007: "Padarincang",
    nokant1008: "Tirtayasa",
    nokant1009: "Anyer",
    nokant2001: "Pakuhaji",
    nokant2002: "Teluknaga",
    nokant2003: "Sepatan",
    nokant2004: "Rajeg",
    nokant2005: "Cisoka",
    nokant2006: "Kemiri",
    nokant2007: "Jambe",
    nokant2008: "Cisauk",
    nokant2009: "Gunung Kaler",
    nokant2010: "Kronjo",
    nokant2011: "Pasar Kemis",
    nokant2012: "Cikupa",
    nokant2013: "Sukamulya",
    nokant3001: "Kalang Anyar",
    nokant3002: "Warung Gunung",
    nokant4001: "Mandalawangi",
  };

  const alignRightKeys = ["nokant1001", "nokant1002", "nokant1003", "nokant1004", "nokant1005", "nokant1006", "nokant1007", "nokant1008", "nokant1009", "nokant2001", "nokant2002", "nokant2003", "nokant2004", "nokant2005", "nokant2006", "nokant2007", "nokant2008", "nokant2009", "nokant2010", "nokant2011", "nokant2012", "nokant2013", "nokant3001", "nokant3002", "nokant4001"];

  return keys.filter(key => !excludeKeys.includes(key)).map(key => ({
    name: key,
    label: columnNamesMap[key] || key,
    options: {
      filter: false,
      sort: false,
      setCellProps: () => ({
        align: alignRightKeys.includes(key) ? "right" : "left",
      }),
      customBodyRenderLite: (dataIndex) => {
        const value = data[dataIndex][key];
        // Check if 'ketera' for the row is an empty string
        if (data[dataIndex].ketera === '') {
          // If 'ketera' is empty and key is in alignRightKeys, render an empty string
          if (alignRightKeys.includes(key)) {
            return "";
          }
        }

        // For numeric fields, format the number
        if (alignRightKeys.includes(key)) {
          return (
            <Box sx={{ color: parseFloat(value) < 0 ? 'error.main' : 'inherit' }}>
              <NumericFormat value={value != null ? parseFloat(value).toFixed(0) : 0} displayType={'text'} thousandSeparator />
            </Box>
          );
        }

        // Default rendering for non-numeric fields or when 'ketera' is not an empty string
        return value;
      },
    },
  }));
};

export default function CabangAbsenPage() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedBerjalan, setSelectedBerjalan] = useState(false);

  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const currentYear = new Date().getFullYear();
  const startYear = 2019;
  const yearOptions = generateYearOptions(startYear, currentYear);

  const months = [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
  ];

  const monthOptions = months.map((month, index) => ({
    value: index + 1,
    label: month
  }));

  const berjalanOptions = [
    {value: true, label: "Ya"},
    {value: false, label: "Tidak"}
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 25, 50, 100],
    downloadOptions: {
      filename: `Kinerja-${months[selectedMonth - 1]}-${selectedYear}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      }
    },
  };

  const filterData = () => {
    setTableLoading(true);

    axios.post(`${process.env.REACT_APP_SIRKAH_API_URL}/laporan/laporan-kinerja-petugas/group/by-kantor`, {year: selectedYear, month: selectedMonth, berjalan: selectedBerjalan})
    .then((response) => {
      // console.log(JSON.stringify(response?.data));
      setData(response?.data?.data)
    })
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false));
  }

  return (
    <>
      <Helmet>
        <title> Laporan Kinerja Kantor | Sip App </title>
      </Helmet>
      <Container
        sx={{
          mt: 5
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="start">
            <Box sx={{ marginRight: 2 }}>
              <Select
                displayEmpty
                value={selectedYear || ""}
                onChange={(e) => setSelectedYear(e.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  Pilih Tahun
                </MenuItem>
                {yearOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box sx={{ marginRight: 2 }}>
              <Select
                displayEmpty
                value={selectedMonth || ""}
                onChange={(e) => setSelectedMonth(e.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  Pilih Bulan
                </MenuItem>
                {monthOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box sx={{ marginRight: 2 }}>
              <Select
                displayEmpty
                value={selectedBerjalan || ""}
                onChange={(e) => setSelectedBerjalan(e.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  Berjalan
                </MenuItem>
                {berjalanOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Button variant="contained" onClick={filterData}>
              Submit
            </Button>
          </Stack>
        </Box>
        <Card>
          <MUIDataTable
            title="Laporan Kinerja Kantor"
            data={data}
            columns={generateColumns(data)}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>
    </>
  );
}
