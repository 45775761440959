import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
// API
import { axiosPublic } from "../common/axiosPublic";
// import { axiosPrivate } from "../common/axiosPrivate";
 
const AuthContext = createContext();
 
export const AuthContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(() => {
    const userProfile = localStorage.getItem('userData');
    if (userProfile) {
      return JSON.parse(userProfile);
    }
    return null;
  });
  const navigate = useNavigate();
  
  const login = async (payload) => {
    setLoading(true);
    try {
      const response = await axiosPublic.post("/login", payload);
      const status = response?.status;
      const token = response?.data?.token;
  
      if (status === 200) {
        Cookies.set('api_token', token, { expires: 1 });
        localStorage.setItem('userData', JSON.stringify(response?.data));
        setUser(response?.data);
      }

      return response;
    } catch (error) {
      return error.response;
    } finally {
      setLoading(false);
    }
  };
  
  const logout = async () => {
    // await api.get("http://localhost:4000/logout", { withCredentials: true });
    localStorage.removeItem('userData');
    setUser(null);
    navigate('/login');
  };
  // const saveUser = async () => {
  //   const apiResponse = await axiosPrivate.get("/get-user");
  //   localStorage.setItem('userData', JSON.stringify(apiResponse?.data?.user));
  //   setUser(apiResponse?.data?.user);
  //   navigate('/');
  // }

  return (
    <>
      <AuthContext.Provider value={{ loading, user, login, logout }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};
 
export default AuthContext;
