import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo, useContext } from 'react';
// @mui
import {
  Stack,
  Card,
  Container,
  IconButton,
  Avatar,
  Typography,
  Button,
  Grid,
  Modal,
  Box,
  CircularProgress
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { id } from "date-fns/locale";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from "@mui/icons-material/Close";
import { format, parseISO } from 'date-fns';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import Iconify from '../../../components/iconify';
import AddPromosiPage from './AddPromosiPage';
import EditPromosiPage from './EditPromosiPage';
import { getCurrentDate, customDate } from "../../../utils/formatDate";
import NotificationContext from '../../../context/notificationContext';
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function PromosiPage() {
  const notificationCtx = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedId, setSelectedId] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
     name: "nama",
     label: "Nama",
     options: {
      filter: false,
      sort: true,
      customBodyRender: (nama, meta) => {
        const profilUrlId = meta.rowData[12];
        const profilUrl = profilUrlId ? `https://drive.google.com/thumbnail?id=${profilUrlId}` : '';
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={profilUrl} src={profilUrl} imgProps={{ referrerPolicy: "no-referrer" }} />
            <Typography variant="subtitle2" noWrap>
              {nama}
            </Typography>
          </Stack>
        )
      }
     }
    },
    {
     name: "kodePegawai",
     label: "Kode",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "dariJabatan",
      label: "Jabatan Lama",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "keJabatan",
      label: "Jabatan Baru",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "tglMulai",
      label: "Tgl Efektif",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => Object.prototype.toString.call(value) ? format(parseISO(value), 'dd/MM/yyyy') : format(new Date(value), 'dd/MM/yyyy'),
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return [`Efektif Dari: ${startDate}`, `Sampai Dengan: ${enDate}`];
            } if (v[0]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');

              return `Efektif Dari: ${startDate}`;
            } if (v[1]) {
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return `Sampai Dengan: ${enDate}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          names: [],
          logic(date, filters) {
            const formated = new Date(customDate(date));

            if (filters[0] && filters[1]) {
              return !(formated >= filters[0] && formated <= filters[1]);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <>
              <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Efektif Dari"
                      value={filterList[index][0] || null}
                      onChange={date => {
                        filterList[index][0] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Sampai Dengan"
                      value={filterList[index][1] || null}
                      onChange={date => {
                        filterList[index][1] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </>
          ),
        },
      },
    },
    {
      name: "tglAkhir",
      label: "Tgl Akhir",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
    name: "keterangan",
    label: "Keterangan",
    options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "dibuatOleh",
      label: "Dibuat Oleh",
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: "tglBuat",
      label: "Tgl Buat",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "tglUbah",
      label: "Tgl Ubah",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "profilUrlId",
      label: "profilUrlId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      }
     },
     {
      name: "_id",
      label: "Aksi",
      options: {
       filter: false,
       sort: false,
       customHeadRender: () => null,
       customBodyRender: (value) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleOpenModal(1, value)}
        >
          <Iconify icon={'eva:edit-fill'} />
          Edit
        </Button>
       )
      }
     }
   ];
   
  const options = {
    filterType: "dropdown",
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => deleteLoading ? (
      <CircularProgress
        sx={{
          color: '#637381',
          mr: 2
        }}
        size={20}
      />
    ) : (
      <DeleteIcon
        size={20}
        sx={{
          color: '#637381',
          cursor: 'pointer',
          mr: 2
        }}
        onClick={() => handleDelete(selectedRows, displayData, setSelectedRows)}
      />
    ),
    downloadOptions: {
      filterOptions: {
        filename: `promosi-${getCurrentDate('-')}.csv`,
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  const handleOpenModal = (content, id) => {
    setSelectedId(id);
    setModalContent(content);
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleUpdateTable = (newData) => {
    const index = data.findIndex(item => item._id === newData._id);
  
    if (index !== -1) {
      data[index] = { ...data[index], ...newData };
      setData([...data]);
    } else {
      setData([newData, ...data]);
    }
  };

  const handleDelete = (selectedRows, displayData, setSelectedRows) => {
    setDeleteLoading(true);

    const ids = selectedRows.data.map(d => displayData[d.index].data[0]);

    axiosPrivate.post('/promosi/destroy', {'data' : ids})
    .then((response) => {
      const count = response?.data?.deletedCount;

      if(count > 0) {
        ids.map((item) => setData(prev => prev.filter(e => e._id !== item)));
        setSelectedRows([]);
      }

      if(count > 0) {
        setData(prev => prev.filter(e => e._id !== ids))
      }

      handleMessage(response);
    })
    .catch((error) => handleMessage(error?.response))
    .finally(() => setDeleteLoading(false));
  }

  const handleMessage = async (params) => {
    const count = params?.data?.deletedCount;

    if(count > 0) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: "Data Berhasil Dihapus."
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: "Data Gagal Dihapus!"
      });
    }
  };

  // Fetch Api
  useEffect(() => {
    axiosPrivate.get('promosi')
    .then((response) => setData(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, []);

  return (
    <>
      <Helmet>
        <title> List Pegawai | Sip App </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 2 }}>
          <Button
            onClick={() => handleOpenModal(0)}
            variant='contained'
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Tambah
          </Button>
        </Stack>
        <Card>
          <MUIDataTable
            title="List Promosi"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        hideBackdrop
        open={modalOpen}
        onClose={handleCloseModal}
        style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {modalContent === 0 ? (
            <AddPromosiPage
              handleCloseModal={handleCloseModal}
              handleUpdateTable={handleUpdateTable}
            />
          ) : (
            <EditPromosiPage
              id={selectedId}
              handleCloseModal={handleCloseModal}
              handleUpdateTable={handleUpdateTable}
            />
          )}
        </>
      </Modal>
    </>
  );
}
