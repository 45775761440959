import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
// @mui
import {
  Card,
  Box,
  Stack,
  Container,
  Select,
  MenuItem
} from '@mui/material';
import { format, parseISO, parse, differenceInDays, isAfter, isValid, addMonths } from 'date-fns';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import { getCurrentDate } from "../../../utils/formatDate";
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function LapPegawaiKontrakPage() {
  const [data, setData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
     name: "nama",
     label: "Nama",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "kodePegawai",
     label: "Kode",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "kantor",
      label: "Kantor",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "jabatan",
      label: "Jabatan",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "statusPegawai",
      label: "Status Pegawai",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "tglMulaiKontrak",
      label: "Tgl Mulai Kontrak",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "tglAkhirKontrak",
      label: "Tgl Akhir Kontrak",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "kontrakMagangKe",
      label: "Magang Ke",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { textAlign: 'center' } })
      }
    },
    {
      name: "kontrakPkwtKe",
      label: "PKWT Ke",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { textAlign: 'center' } })
      }
    },
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `pegawai-kontrak-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    },
    setRowProps: (row) => {
      const contractEndDate = row[8]; // Assuming tglAkhirKontrak is at index 8

      let parsedDate = parseISO(contractEndDate);
      if (!isValid(parsedDate)) {
        parsedDate = parse(contractEndDate, 'dd/MM/yyyy', new Date());
      }

      if (!isValid(parsedDate)) {
        console.error('Invalid date:', contractEndDate);
        return {};
      }

      const today = new Date();
      const isWithinTwoWeeks = differenceInDays(parsedDate, today) <= 14 && differenceInDays(parsedDate, today) >= 0;
      const isPastToday = isAfter(today, parsedDate);

      let style = {};

      if (isWithinTwoWeeks) {
        style = { backgroundColor: 'rgba(255, 193, 7, 0.16)' };
      }

      if (isPastToday) {
        style = { backgroundColor: 'rgba(255, 0, 0, 0.16)' };
      }

      return {
        style
      };
    },
  };

  const status = [ "Semua", "Dibawah 3 Bulan", "Dibawah 6 Bulan", "Sedang Berjalan", "Habis Masa Berlaku" ];

  const statusOptions = status.map((month, index) => ({
    value: index,
    label: month
  }));

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const isTwoWeeksToday = (dateString) => {
    let contractEndDate = parseISO(dateString);

    if (!isValid(contractEndDate)) {
      contractEndDate = parse(dateString, 'dd/MM/yyyy', new Date());
    }

    if (!isValid(contractEndDate)) {
      console.error('Invalid date:', dateString);
      return false;
    }

    const today = new Date();
    return differenceInDays(contractEndDate, today) <= 14 && differenceInDays(contractEndDate, today) >= 0;
};

const isUnderSixMonths = (dateString) => {
  const startDate = parse(dateString, 'dd/MM/yyyy', new Date());
  if (!isValid(startDate)) {
    console.error('Invalid date:', dateString);
    return false;
  }
  const sixMonthsAgo = addMonths(new Date(), -6);
  return startDate >= sixMonthsAgo;
};

  // Fetch Api
  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        const response = await axiosPrivate.post('laporan/pegawai-kontrak', { status: selectedStatus });
        const fetchedData = response.data;
          
        // Sort data to prioritize contracts that have expired
        const sortedData = fetchedData.sort((a, b) => {
          const aParsedDate = parseISO(a.tglAkhirKontrak);
          const bParsedDate = parseISO(b.tglAkhirKontrak);

          const aIsPastToday = isAfter(new Date(), aParsedDate);
          const bIsPastToday = isAfter(new Date(), bParsedDate);

          const aIsTwoWeeks = isTwoWeeksToday(a.tglAkhirKontrak);
          const bIsTwoWeeks = isTwoWeeksToday(b.tglAkhirKontrak);
          
          const aIsUnderSixMonths = isUnderSixMonths(a.tglMulaiKontrak);
          const bIsUnderSixMonths = isUnderSixMonths(b.tglMulaiKontrak);

          if (aIsPastToday && !bIsPastToday) {
            return -1;
          }
          if (!aIsPastToday && bIsPastToday) {
            return 1;
          }
          if (aIsTwoWeeks && !bIsTwoWeeks) {
            return -1;
          }
          if (!aIsTwoWeeks && bIsTwoWeeks) {
            return 1;
          }
          if (aIsUnderSixMonths && !bIsUnderSixMonths) {
            return -1;
          }
          if (!aIsUnderSixMonths && bIsUnderSixMonths) {
            return 1;
          }
          return 0; // Keep original order if neither or both conditions are met
        });
  
        setData(sortedData);
      } catch (error) {
        console.error(error);
      } finally {
        setTableLoading(false);
      }
    };
  
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus]);
  

  return (
    <>
      <Helmet>
        <title> Laporan Pegawai Kontrak | Sip App </title>
      </Helmet>

      <Container
        sx={{
          mt: 5
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="start">
            <Box sx={{ marginRight: 2 }}>
              <Select
                value={selectedStatus}
                onChange={handleChange}
              >
                {statusOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Stack>
        </Box>
        <Card>
          <MUIDataTable
            title="Pegawai Kontrak"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>
    </>
  );
}
