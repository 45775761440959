import { Popover, MenuItem } from '@mui/material';
import Iconify from '../../../components/iconify';

function PegawaiPopover({ open, anchorEl, handleCloseMenu, handleOpenModal }) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      slotProps={{
        paper: {
          sx: {
            p: 1,
            width: 'auto',
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }
      }}
    >
      <MenuItem onClick={() => handleOpenModal(1)}>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Profil
      </MenuItem>

      <MenuItem onClick={() => handleOpenModal(2)}>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Akun
      </MenuItem>

      <MenuItem onClick={() => handleOpenModal(3)}>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Kepegawaian
      </MenuItem>

      <MenuItem onClick={() => handleOpenModal(4)}>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Foto
      </MenuItem>

      <MenuItem onClick={() => handleOpenModal(5)}>
        <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
        Detil
      </MenuItem>

      <MenuItem onClick={() => handleOpenModal(6)}>
        <Iconify icon={'eva:file-text-fill'} sx={{ mr: 2 }} />
        Dokumen
      </MenuItem>

      <MenuItem onClick={() => handleOpenModal(7)}>
        <Iconify icon={'eva:activity-outline'} sx={{ mr: 2 }} />
        Kinerja
      </MenuItem>

      {/* <MenuItem onClick={() => handleOpenModal(8)}>
        <Iconify icon={'eva:map-outline'} sx={{ mr: 2 }} />
        Absen Rumpun
      </MenuItem> */}
    </Popover>
  );
}

export default PegawaiPopover;
