import * as Yup from 'yup';
import pegawaiKeluarFormModel from './pegawaiKeluarFormModel';

const {
  formField: {
    kodePegawai,
    tglKeluar,
    sebab
  }
} = pegawaiKeluarFormModel;

export default Yup.object({
  [kodePegawai.name]: Yup.string().required(`${kodePegawai.requiredErrorMsg}`),
  [tglKeluar.name]: Yup.string().required(`${tglKeluar.requiredErrorMsg}`),
  [sebab.name]: Yup.string().required(`${sebab.requiredErrorMsg}`)
});
