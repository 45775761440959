import { Container, Typography, Stack } from '@mui/material';
import { JabatanForm } from '../../../sections/@dashboard/jabatan';

const AddJabatanPage = ({handleCloseModal, handleUpdateTable}) => (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tambah Jabatan
          </Typography>
        </Stack>
        <JabatanForm
          handleCloseModal={handleCloseModal}
          handleUpdateTable={handleUpdateTable}
        />
      </Container>
    </>
  );
  
export default AddJabatanPage;
