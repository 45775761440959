import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@mui/material';

export default function InputField(props) {
  const { maxValue, readOnly, ...rest } = props;
  const validate = value => {
    let error;
    if (maxValue !== undefined && value > maxValue) {
      error = `Maksimal hari ${maxValue}`;
    }
    return error;
  };

  const [field, meta] = useField({ ...props, validate });

  const _renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }

    return false;
  }

  return (
    <TextField
      type="text"
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      InputProps={{ readOnly }}
      {...field}
      {...rest}
    />
  );
}
