import { useEffect, useState } from 'react';
import { Container, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { UserForm } from '../../sections/@dashboard/user';
import { axiosPrivate } from '../../common/axiosPrivate';

const AddUserPage = ({handleCloseModal, handleUpdateTable}) => {
  const [dropDown, setDropDown] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    axiosPrivate.get('user/dropdown/list')
    .then((response) => setDropDown(response?.data))
    .catch((error) => console.log(error))
    .finally(() => setPageLoading(false));
  }, []);

  return (
    <>
    {pageLoading ? (
      <Box
        sx={{
          flexGrow: 1
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"100%"}
        width={"100%"}
      >
        <CircularProgress />
      </Box>) : (
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Tambah User
          </Typography>
        </Stack>
        <UserForm
          kantorDropDown={dropDown}
          handleCloseModal={handleCloseModal}
          handleUpdateTable={handleUpdateTable}
        />
      </Container>
    )}
  </>
  );
}
  
export default AddUserPage;
