import { useContext } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import NotificationContext from '../../context/notificationContext';

const AlertBox = () => {
  const notificationCtx = useContext(NotificationContext);

  return (
    <Snackbar
      open={notificationCtx.open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      autoHideDuration={3000}
      onClose={() => notificationCtx.clear()}
    >
    <Alert severity={notificationCtx.severity}>
      <AlertTitle>{notificationCtx.title}</AlertTitle>
      {notificationCtx.message}
    </Alert>
  </Snackbar>
  );
}

export default AlertBox;
