export function getCurrentDate(separator = '') {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDate = date < 10 ? `0${date}` : `${date}`;

  return `${year}${separator}${formattedMonth}${separator}${formattedDate}`;
}

export function customDate(dateStr) {
  const parts = dateStr.split('/');
  if (parts.length === 3) {
    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript
    const day = parseInt(parts[0], 10);

    if (!Number.isNaN(year) && !Number.isNaN(month) && !Number.isNaN(day)) {
      return new Date(year, month, day);
    }
  }
  return null;
}

export function dateDiff(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Calculate the difference in milliseconds
  const diffMilliseconds = endDate - startDate;

  // Calculate the number of milliseconds in a day, month, and year
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisecondsPerMonth = millisecondsPerDay * 30.44; // Average days per month
  const millisecondsPerYear = millisecondsPerMonth * 12;

  // Calculate the total years, months, and days
  const years = Math.floor(diffMilliseconds / millisecondsPerYear);
  const months = Math.floor((diffMilliseconds % millisecondsPerYear) / millisecondsPerMonth);
  const days = Math.floor((diffMilliseconds % millisecondsPerMonth) / millisecondsPerDay);

  return { years, months, days };
}
