import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Box, Card, CardHeader } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppConversionRates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};

export default function AppConversionRates({ title, subheader, chartData, ...other }) {
  const sortData = chartData.sort((a, b) => a.number - b.number);

  const chartLabels = sortData.map((i) => i.label);

  const chartSeries = sortData.map((i) => i.value);

  const chartOptions = useChart({
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        barHeight: '80%'
      },
    },
    dataLabels: {
      enabled: true,
      formatter (val) {
        return val;
      },
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: chartLabels,
      labels: {
        formatter (val) {
          return val.toFixed(0);
        }
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
