import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  IconButton,
  Modal,
  CardMedia,
  CircularProgress
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { format, parseISO } from 'date-fns';
import Iconify from '../../../components/iconify';
import { getCurrentDate } from '../../../utils/formatDate';
import { axiosPrivate } from '../../../common/axiosPrivate';

// Create Document Component
const ViewAbsenRumpunPage = ({ absen }) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [pegawai, setPegawai] = useState({});
  const [rumpuns, setRumpuns] = useState([]);
  const [absens, setAbsens] = useState([]);
  const [tanggalRumpun, setTanggalRumpun] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [imagebase64, setImagebase64] = useState(null)

  const MarkerComponent = ({ text }) => (
    <>
      <Typography variant="subtitle">{ text }</Typography>
      <Iconify sx={{ color: "#FF4842" }} icon={'line-md:map-marker-filled'} />
    </>
  );

  const displayPhoto = (url) => {
    setImagebase64(url);
    handleOpenModal();
  }

  const handleOpenModal = () => {
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  // Fetch Api
  useEffect(() => {
    if (absen) {
      setTanggalRumpun(absen[2]);

      const getPegawai = async () => {
        try {
          const response = await axiosPrivate.get(`/pegawai/bykode/${absen[3]}`);
          const data = response?.data;

          setPegawai(data);

          const dateObject = new Date(absen[2]);
          const dayNumber = dateObject.getDay();
          const resRumpuns = await axiosPrivate.post(`${process.env.REACT_APP_SIRKAH_API_URL}/rumpun/sip/rumpun/${data?.kantor}`, {nik: data?.kodePegawai, harlaya: dayNumber});
          const rumpuns = resRumpuns?.data;
          setRumpuns(rumpuns);

          // After successfully fetching the Pegawai, fetch the data based on the date
          const resAbsens = await axiosPrivate.post(`pegawai-absen-rumpun/get-by-date`, { kodePegawai: data?.kodePegawai, tanggal: absen[2] });
          const absens = resAbsens?.data;

          setAbsens(absens);
        } catch (error) {
          console.error(error);
        } finally {
          setPageLoading(false);
        }
      };

      getPegawai();
    }
  }, [absen]);

  return (
    <>
      <Container component="main" maxWidth="md">
        {pageLoading ? (
          <Box
            sx={{
              flexGrow: 1,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={"100%"}
            width={"100%"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h6">{pegawai?.nama}</Typography>
            <Stepper orientation="vertical">
              {rumpuns.map((item) => {
                const jamlaya = item?.JAMLAYA.replace(".", ":");
                const active = absens.some(dataItem => dataItem.rumpun?.kode === item.ID);
                const absen = absens.find(dataItem => dataItem.rumpun?.kode === item.ID);
                const tanggal = tanggalRumpun ? format(parseISO(tanggalRumpun), 'dd MMM yyyy') : format(new Date(getCurrentDate('/')), 'dd MMM yyyy');
                // const absenTime = absen?.tanggal ? format(utcToZonedTime(parseISO(absen?.tanggal), 'UTC'), 'HH:mm', { timeZone: 'UTC' }) : '';
                // const numbJamlaya = parseInt(jamlaya.replace(/[.:]/g, ""), 10);
                // const numbTime = parseInt(absenTime.replace(/[.:]/g, ""), 10);

                // console.log(absen)

                return (
                  <Step key={item.ID} active={active} expanded>
                    <StepLabel
                      optional={
                        <Grid container alignItems="center" justify="space-between">
                          <Typography variant="caption">
                            {`${tanggal} ${jamlaya}`}
                          </Typography>
                          {absen?.tanggal && <Button
                            variant="text"
                            style={{ marginLeft: 'auto' }}
                            onClick={() => displayPhoto(absen?.foto)}
                          >
                            <Iconify sx={{ color: "#637381" }} icon="iconamoon:camera-image-fill" />
                          </Button>}
                        </Grid>
                      }
                    >
                      {item.keKantor}
                    </StepLabel>
                    <StepContent>
                      {/* <Grid container alignItems="center" justify="space-between">
                        <Typography variant="caption" sx={{ color: numbJamlaya < numbTime ? '#FF4842' : '#2065D1' }}>
                          {absen?.tanggal && (
                            <>
                              <span>
                                {format(utcToZonedTime(parseISO(absen?.tanggal), 'UTC'), 'dd MMM yyyy HH:mm', { timeZone: 'UTC' })}
                              </span>
                              <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                {numbJamlaya < numbTime ? (<Iconify icon="iconamoon:close-fill" />) : (<Iconify icon="iconamoon:check-bold" />)}
                              </span>
                            </>
                          )}
                        </Typography>
                        {absen?.tanggal && <Button
                          variant="text"
                          style={{ marginLeft: 'auto' }}
                          onClick={() => displayPhoto(absen?.foto)}
                        >
                          <Iconify sx={{ color: "#637381" }} icon="iconamoon:camera-image-fill" />
                        </Button>}
                      </Grid> */}
                      <Typography variant="body2">
                        {`RUMPUN ${item.NAMA} DESA ${item.DESA} KEC. ${item.KECAMATAN}, ${item.KABUPATEN}`}
                      </Typography>
                      {absen?.latitude && absen?.longitude && <div style={{ height: '200px', width: '100%' }}>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
                          defaultCenter={{
                            lat: parseFloat(absen?.latitude),
                            lng: parseFloat(absen?.longitude)
                          }}
                          defaultZoom={11}
                        >
                          <MarkerComponent
                            lat={parseFloat(absen?.latitude)}
                            lng={parseFloat(absen?.longitude)}
                            text={`RUMPUN ${absen?.rumpun.nama}`} />
                        </GoogleMapReact>
                      </div>}
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </>
        )}
      </Container>
      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <div>
          {imagebase64 ? (
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              // p: 0,
            }}>
              <Box
                sx={{
                  position: "absolute",
                  top: -12,
                  right: -12
                }}
              >
                <IconButton
                  edge="end"
                  size="small"
                  color="error"
                  onClick={handleCloseModal}
                  disableRipple
                  aria-label="close"
                  sx={{
                    backgroundColor: '#fff',
                    boxShadow: 24,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <CardMedia
                component="img"
                src={imagebase64}
                alt={"alt"}
                title={"titleasdasdsada"}
                // sx={{ padding: "1em"}}
              />
            </Box>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={"100%"}
              width={"100%"}
            >
              <CircularProgress />
            </Box>
          )}
        </div>
      </Modal>
    </>
  )
};

export default ViewAbsenRumpunPage;
